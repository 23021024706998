<div class="list-slider">
  <div class="button-area">
    <div
      class="next-btn"
      [ngClass]="{ 'has-btn': canPrevious, 'no-gradient': noGradient }"
    >
      <button
        class="hr-button-rounded hr-button-icon"
        [disabled]="!canPrevious"
        (click)="scrollLeft()"
      >
        <svg-icon
          class="hr-svg-icon reverted"
          src="/assets/icons/chevron-right.svg"
        ></svg-icon>
      </button>
    </div>
  </div>

  <div class="list-area" #listContainer (window:resize)="onResize($event)">
    <ng-content></ng-content>
  </div>

  <div class="button-area">
    <div
      class="next-btn"
      [ngClass]="{ 'has-btn': canNext, 'no-gradient': noGradient }"
    >
      <button
        class="hr-button-rounded hr-button-icon"
        [disabled]="!canNext"
        (click)="scrollRight()"
      >
        <svg-icon
          class="hr-svg-icon"
          src="/assets/icons/chevron-right.svg"
        ></svg-icon>
      </button>
    </div>
  </div>
</div>
