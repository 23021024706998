<div
  class="close hr-tooltip-trigger"
  (click)="close(undefined)"
  *ngIf="data?.closeButton"
>
  <svg-icon
    class="hr-svg-icon"
    src="/assets/icons/components/close-x.svg"
  ></svg-icon>

  <div class="hr-tooltip-content hr-mini-tooltip">
    <span>Cancelar</span>
  </div>
</div>

<p
  *ngIf="data?.message"
  style="max-width: 320px; word-break: break-word"
  [ngStyle]="{ 'margin-top': data?.closeButton ? '20px' : '0px' }"
  [innerHTML]="data?.message"
></p>
<!-- [ngClass]="btn?.class" -->

<ng-container *ngIf="data?.buttons?.length > 0">
  <button
    class="hr-btn hr-btn-primary poster-btn"
    *ngFor="let btn of data?.buttons"
    (click)="close(btn?.value)"
    style="max-width: 320px"
  >
    {{ btn?.label }}
  </button>
</ng-container>
