import { Directive, ElementRef, HostListener, OnInit } from '@angular/core';

@Directive({
  selector: '[hrResizeTextarea]',
})
export class ResizeTextareaDirective implements OnInit {
  private textArea: HTMLTextAreaElement;

  constructor(public element: ElementRef<HTMLElement>) {
    this.textArea = this.element.nativeElement as HTMLTextAreaElement;
  }

  ngOnInit() {
    this.resizeTextArea();
  }

  @HostListener('input', ['$event']) onInput(event: KeyboardEvent) {
    this.resizeTextArea();
  }

  @HostListener('window:resize') onWindowsResize() {
    this.resizeTextArea();
  }

  private resizeTextArea() {
    this.textArea.style.height = '1px';

    if (this.textArea.scrollHeight > 80) {
      this.textArea.style.height = this.textArea.scrollHeight + 'px';
    } else {
      this.textArea.style.height = '80px';
    }
  }
}
