import { Component, OnDestroy, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ITemplate } from 'src/app/models/templates.model';
import { PropostaSalarialService } from 'src/app/services/proposta-salarial.service';

@Component({
  selector: 'hr-template',
  templateUrl: './template.component.html',
  styleUrls: ['./template.component.scss'],
})
export class TemplateComponent implements OnInit, OnDestroy {
  template!: ITemplate;
  slideIndex: string;

  constructor(
    private route: ActivatedRoute,
    private propostaSalarialService: PropostaSalarialService
  ) {}

  ngOnInit() {
    if (
      this.route &&
      this.route.snapshot &&
      this.route.snapshot.data['proposta']
    ) {
      this.slideIndex = this.route.snapshot.data['proposta'].slideIndex;
      this.loadTemplate();
    }
  }

  ngOnDestroy() {
    this.propostaSalarialService.onDestroy$.next(null);
    this.propostaSalarialService.onDestroy$.complete();
  }

  loadTemplate() {
    this.propostaSalarialService
      .loadTemplate()
      .subscribe((templateData: ITemplate) => {
        this.template=  templateData;
      });
  }
}
