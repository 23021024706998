import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { ITemplateRejectedOffer } from 'src/app/models/templates.model';
import { SlideBaseComponent } from 'src/app/shared/slider/slide/slide-base.component';

@Component({
  selector: 'hr-dynamic-rejected-offer',
  templateUrl: './dynamic-rejected-offer.component.html',
  styleUrls: ['./dynamic-rejected-offer.component.scss'],
})
export class DynamicRejectedOfferComponent extends SlideBaseComponent {
  override initialValue: ITemplateRejectedOffer;

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  protected onInit(): void {}
  protected onDestroy(): void {}

  protected initForm(): void {
    this.form = this.formBuilder.group({
      slideName: [null, [Validators.required, Validators.maxLength(20)]],
      title: [null, [Validators.required, Validators.maxLength(40)]],
      description: [null, [Validators.required, Validators.maxLength(1500)]],
      firstImage: [null, [Validators.required]],
      firstImageUrl: [null, [Validators.required]],
    });
  }
}
