import {
  AfterViewChecked,
  AfterViewInit,
  Component,
  ElementRef,
  Input,
  ViewChild,
} from '@angular/core';

@Component({
  selector: 'hr-list-slider',
  templateUrl: './list-slider.component.html',
  styleUrls: ['./list-slider.component.scss'],
})
export class ListSliderComponent implements AfterViewInit, AfterViewChecked {
  @Input() scrollLength = 350;
  @Input() noGradient = false;
  @ViewChild('listContainer') listContainer!: ElementRef<HTMLDivElement>;
  // @HostListener('window:resize', ['$event'])

  canPrevious = false;
  canNext = false;
  leftLimit: number;
  rightLimit: number;
  offset: number;

  private content = '';

  ngAfterViewInit(): void {
    this.updateButtons();
  }

  ngAfterViewChecked(): void {
    const c = this.listContainer.nativeElement.innerHTML;
    if (c !== this.content) {
      this.content = c;
      this.updateButtons();
    }
  }

  onResize(event) {
    if (event.target.innerWidth <= 1679) {
      this.updateButtons();
    }
  }

  scrollLeft() {
    const newValue = this.leftLimit - this.scrollLength;
    this.listContainer.nativeElement.scrollTo({ left: newValue, behavior: 'smooth' });
    this.updateButtons(newValue);
  }

  scrollRight() {
    const newValue = this.leftLimit + this.scrollLength;
    this.listContainer.nativeElement.scrollTo({ left: newValue, behavior: 'smooth' });
    this.updateButtons(newValue);
  }

  private updateButtons(newValue?: number) {
    setTimeout(() => {
      if (newValue !== undefined) {
        this.leftLimit = newValue > 0 ? newValue : 0;
      } else {
        this.leftLimit = this.listContainer.nativeElement.scrollLeft;
      }

      this.rightLimit = this.listContainer.nativeElement.scrollWidth;
      this.offset = this.listContainer.nativeElement.offsetWidth;
      this.canPrevious = this.leftLimit > 0;
      this.canNext = this.rightLimit - this.offset > this.leftLimit;

      if (!this.canNext) {
        this.leftLimit = this.rightLimit - this.offset;
      }
    }, 0);
  }
}
