class ArrayUtils {
  public filter<T>(array: T[], filter: string, fields?: string[]): T[] {
    return this._filter(array, filter, fields);
  }

  public strictFilter<T>(array: T[], filter: string, fields?: string[]): T[] {
    return this._filter(array, filter, fields, true);
  }

  private _filter<T>(array: T[], filter: string, fields?: string[], equals = false): T[] {
    if (!filter) {
      return array;
    }

    return array.filter(item => {
      if (fields) {
        let match = false;

        fields.forEach(field => {
          let value: any = item;

          field.split('.').forEach(f => {
            value = value[f];
          });

          if (equals && String(value).toLocaleLowerCase() === filter.toLocaleLowerCase()) {
            match = true;
            return;
          }

          if (!equals && String(value).toLocaleLowerCase().includes(filter.toLocaleLowerCase())) {
            match = true;
            return;
          }
        });

        return match;
      } else {
        return String(item).toLocaleLowerCase().includes(filter.toLocaleLowerCase());
      }
    });
  }

  public orderBy<T>(array: T[], order: orderType, field?: string): T[] {
    if (field) {
      return array.sort((a: any, b: any) => {
        field.split('.').forEach(f => {
          a = a[f];
          b = b[f];
        });

        a = typeof a === 'string' ? a.toLocaleLowerCase() : a;
        b = typeof b === 'string' ? b.toLocaleLowerCase() : b;

        return order === 'ASC' ? (a < b ? -1 : a > b ? 1 : 0) : a > b ? -1 : a < b ? 1 : 0;
      });
    } else {
      return array.sort((a: any, b: any) => {
        a = typeof a === 'string' ? a.toLocaleLowerCase() : a;
        b = typeof b === 'string' ? b.toLocaleLowerCase() : b;
        return order === 'ASC' ? (a < b ? -1 : a > b ? 1 : 0) : a > b ? -1 : a < b ? 0 : 1;
      });
    }
  }
}
export default new ArrayUtils();

type orderType = 'ASC' | 'DESC';
