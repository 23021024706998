import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[hrClickOutside]',
})
export class ClickOutsideDirective {
  private canSend = false;

  @Output() hrClickOutside = new EventEmitter();
  @Input() isOpen;
  constructor(private _elementRef: ElementRef) {}
  @HostListener('document:click', ['$event.target'])
  public onClick(target) {
    if (this.isOpen && !this.canSend) {
      this.canSend = true;
      return;
    }

    if (this.isOpen) {
      const evento = this._elementRef.nativeElement.contains(target);
      if (!evento) {
        this.hrClickOutside.emit(null);
        this.canSend = false;
      }
    }
  }
}
