import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot,
  UrlTree,
} from '@angular/router';
import { BehaviorSubject, Observable, lastValueFrom } from 'rxjs';
import { environment } from 'src/environments/environment';

@Injectable({
  providedIn: 'root',
})
export class AuthService {
  public userId: string;
  public dominioId: string;
  public phoneNumber: string;
  public logoUrl: string;
  public isPreview: boolean;
   matriz: string;
  user = new BehaviorSubject<any>(null)
  constructor(
    protected router: Router,
    protected firestore: AngularFirestore,
    protected http:HttpClient
  ) {
    console.log('authService')
  }

   validateToken(token: string) {
    return this.http.post(environment.urlServerless + 'validSingUpToken', {
      token,
    });
  }
   redirect(msg: string) {
    console.log(msg);
    this.router.navigate(['/']);
    return false;
  }

  setCompanyColor(color: string) {
    color = color || '#ff4c7f';
    const transparentColor = color.startsWith('#')
      ? `${color}4C`
      : `${color.slice(0, -1)}, 0.3)`;

    const darkColor = color.startsWith('#')
      ? `${color}4C`
      : `${color.slice(0, -1)}, 0.9)`;

    document.querySelector('body').style.setProperty('--primary', color);
    document
      .querySelector('body')
      .style.setProperty('--primary-transparent', transparentColor);
    document
      .querySelector('body')
      .style.setProperty('--primary-dark', darkColor);
  }

  private async setupLogoUrl() {
    if (!this.matriz) {
      return;
    }
    try {
      const empresa = await lastValueFrom(this.getMatriz());

      this.setCompanyLogo(empresa.data().logo);
    } catch (err) {
      console.log('company not found');
    }
  }

  private async setupPrimaryColor() {
    if (!this.matriz) {
      return;
    }
    try {
      const empresa = await lastValueFrom(this.getMatriz());
      console.log('empres', empresa.data())
      this.setCompanyColor(empresa.data().cor);
    } catch (err) {
      console.log('company not found');
    }
  }

   async setCompanyProperties() {
    return Promise.all([this.setupPrimaryColor(), this.setupLogoUrl()]);
  }
  private getMatriz() {
    return this.firestore.doc<any>(`${this.matriz}`).get();
  }
  setCompanyLogo(logoUrl: string) {
    this.logoUrl = logoUrl ? logoUrl : ' ';
  }
}
