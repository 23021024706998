import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
  ViewChild,
} from '@angular/core';
import { Subscription } from 'rxjs';
import { ISlide } from '../slider.model';
import { SlideBaseComponent } from './slide-base.component';
import { SlideDirective } from './slide.directive';

@Component({
  selector: 'hr-slide',
  templateUrl: './slide.component.html',
  styleUrls: ['./slide.component.scss'],
})
export class SlideComponent implements OnInit, OnDestroy {
  @ViewChild(SlideDirective, { static: true }) private host!: SlideDirective;
  // @ViewChild(SlideDirective, { read: ViewContainerRef })
  // sliderViewContainer!: ViewContainerRef;
  // @Input() previewMode = false;
  // @Input() canEdit = false;
  @Input() set slide(s: ISlide) {
    this._slide = s;
    this.loadComponent();
  }
  @Output() formEmitter = new EventEmitter();
  @Output() nextSlideEmitter = new EventEmitter();

  private _slide: ISlide;
  private formSubscription: Subscription;

  constructor() {}
  // constructor(private componentFactoryResolver: ComponentFactoryResolver) {}

  ngOnInit() {
    // this.loadComponent();
  }

  ngOnDestroy() {
    if (this.formSubscription) {
      this.formSubscription.unsubscribe();
    }
  }

  loadComponent() {
    // if (this.sliderViewContainer) {
    //   this.sliderViewContainer.clear();
    // }

    this.ngOnDestroy();

    if (!this._slide || (this._slide && !this._slide.component)) {
      return;
    }

    // const componentFactory =
    //   this.componentFactoryResolver.resolveComponentFactory<SlideBaseComponent>(
    //     this._slide.component
    //   );

    // const componentRef =
    //   this.sliderViewContainer.createComponent<SlideBaseComponent>(
    //     componentFactory
    //   );

    const componentRef =
      this.host.viewContainerRef.createComponent<SlideBaseComponent>(
        this._slide.component
      );

    componentRef.instance.title = this._slide.title;
    componentRef.instance.initialValue = this._slide.initialValue
      ? this._slide.initialValue
      : null;
    componentRef.instance.extraInputs = this._slide.extraInputs
      ? this._slide.extraInputs
      : null;
    // componentRef.instance.previewMode = this.previewMode
    //   ? this.previewMode
    //   : false;
    // componentRef.instance.canEdit = this.canEdit ? this.canEdit : false;
    this.formSubscription = componentRef.instance.formEmitter.subscribe(
      (form) => {
        // console.log(`${this._slide.component.name}: emit form event`);
        this.formEmitter.emit(form);
      }
    );

    this.formSubscription = componentRef.instance.nextSlideEmitter.subscribe(
      (s) => {
        // console.log(`${this._slide.component.name}: emit form event`);
        this.nextSlideEmitter.emit(s);
      }
    );
  }
}
