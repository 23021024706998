export const paddingNumber = (n: number, paddingSize = 2): string => {
  let nString = `${n}`;
  while (nString.length < paddingSize) {
    nString = `0${nString}`;
  }
  return nString;
};

export const clock = (value: any, symbol = '...'): any => {
  if (!value) {
    return value;
  }

  if (typeof value === 'number') {
    return value;
  }

  const minutes = Math.floor(value / 60);
  const seconds = minutes ? Math.floor(value % minutes) : Math.floor(value);

  return `${paddingNumber(minutes)}:${paddingNumber(seconds)}`;
};

export const getFormattedDate = (
  date: Date,
  divisor = '/',
  fullYaer?: boolean
) => {
  if (!date) {
    return '';
  }
  return `${paddingNumber(date.getDate())}${divisor}${paddingNumber(
    date.getMonth() + 1
  )}${divisor}${
    fullYaer ? date.getFullYear() : date.getFullYear().toString().substr(2, 4)
  }`;
};

export const getFormattedHours = (date: Date) => {
  if (!date) {
    return '';
  }
  return `${paddingNumber(date.getHours())}:${paddingNumber(
    date.getMinutes()
  )}`;
};
