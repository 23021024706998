import { Pipe, PipeTransform } from '@angular/core';
import { isNumber } from 'lodash';
import { paddingNumber } from '../utils/helpers/date.helpers';

@Pipe({
  name: 'clock',
})
export class ClockPipe implements PipeTransform {
  transform(value: any, symbol = '...'): any {
    if (!value) {
      return value;
    }

    if (!isNumber(value)) {
      return value;
    }

    const minutes = Math.floor(value / 60);
    const seconds = minutes ? Math.floor(value % minutes) : Math.floor(value);

    return `${paddingNumber(minutes)}:${paddingNumber(seconds)}`;
  }
}
