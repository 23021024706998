import { Component, OnInit } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SlideBaseComponent } from 'src/app/shared/slider/slide/slide-base.component';
import {
  ITemplateAcceptedOffer,
  ITemplateCover,
} from 'src/app/models/templates.model';

@Component({
  selector: 'hr-dynamic-accepted-offer',
  templateUrl: './dynamic-accepted-offer.component.html',
  styleUrls: ['./dynamic-accepted-offer.component.scss'],
})
export class DynamicAcceptedOfferComponent extends SlideBaseComponent {
  override initialValue: ITemplateAcceptedOffer;

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  protected onInit(): void {}
  protected onDestroy(): void {}

  protected initForm(): void {
    this.form = this.formBuilder.group({
      slideName: [null, [Validators.required, Validators.maxLength(20)]],
      title: [null, [Validators.required, Validators.maxLength(40)]],
      description: [null, [Validators.required, Validators.maxLength(1500)]],
      firstNote: [null, [Validators.required, Validators.maxLength(1500)]],
      secondNote: [null, [Validators.required, Validators.maxLength(1500)]],
      firstImage: [null, [Validators.required]],
      firstImageUrl: [null, [Validators.required]],
    });
  }
}
