<div class="hr-slide-area">
  <div class="ol-template-back-bar"></div>
  <div class="ol-template-slide-data">
    <div class="slide-data-area">
      <div class="ol-template-slide-info">
        <div
          class="ol-template-slide-title"
          [innerHTML]="initialValue.title"
        ></div>
        <div class="wage-box">
          <div class="wage-title-area">
            <div
              class="title"
              [ngClass]="{
                withouVariableWageTitle: !initialValue.showVariableWage
              }"
            >
              {{ "offerLetter.template.monthlyWage" | translate }}
            </div>
            <div class="title" *ngIf="initialValue.showVariableWage">
              {{ "offerLetter.template.variableWage" | translate }}
            </div>
          </div>
          <div
            class="wages"
            [ngClass]="{
              withouVariableWageSubtitle: !initialValue.showVariableWage
            }"
          >
            <div
              class="wage-area"
              [ngClass]="{
                withouVariableWageSubtitleArea: !initialValue.showVariableWage
              }"
            >
              <div class="icon-area">
                <svg-icon
                  class="hr-svg-icon"
                  src="/assets/icons/modules/carta-oferta/template/wage-offer.svg"
                ></svg-icon>
              </div>
              <div class="wage-data">
                <div class="wage">
                  {{ "general.currencyMark" | translate }}
                  {{ getCurrency(salario) }}
                </div>
                <div
                  class="description"
                  [innerHTML]="initialValue.wageSubtitle"
                ></div>
              </div>
            </div>

            <div class="wage-area" *ngIf="initialValue.showVariableWage">
              <div class="icon-area">
                <svg-icon
                  class="hr-svg-icon"
                  src="/assets/icons/modules/carta-oferta/template/wage-offer.svg"
                ></svg-icon>
              </div>
              <div class="wage-data">
                <div class="wage" [innerHTML]="initialValue.variableWage"></div>
                <div
                  class="description"
                  [innerHTML]="initialValue.variableWageSubtitle"
                ></div>
              </div>
            </div>
          </div>
        </div>
        <div class="wage-box-mobile">
          <div class="wage-container first-wage">
            <div class="wage-title-area">
              <div class="title">
                {{ "offerLetter.template.monthlyWage" | translate }}
              </div>
            </div>

            <div class="wages">
              <div class="wage-area">
                <div class="icon-area">
                  <svg-icon
                    class="hr-svg-icon"
                    src="/assets/icons/modules/carta-oferta/template/wage-offer.svg"
                  ></svg-icon>
                </div>
                <div class="wage-data">
                  <div class="wage">
                    {{ "general.currencyMark" | translate }} {{ salario }}
                  </div>
                  <div
                    class="description"
                    [innerHTML]="initialValue.wageSubtitle"
                  ></div>
                </div>
              </div>
            </div>
          </div>
          <div
            class="wage-container second-wage"
            *ngIf="initialValue.showVariableWage"
          >
            <div class="wage-title-area">
              <div class="title">
                {{ "offerLetter.template.variableWage" | translate }}
              </div>
            </div>
            <div class="wages">
              <div class="wage-area">
                <div class="icon-area">
                  <svg-icon
                    class="hr-svg-icon"
                    src="/assets/icons/modules/carta-oferta/template/wage-offer.svg"
                  ></svg-icon>
                </div>
                <div class="wage-data">
                  <div
                    class="wage"
                    [innerHTML]="initialValue.variableWage"
                  ></div>
                  <div
                    class="description"
                    [innerHTML]="initialValue.variableWageSubtitle"
                  ></div>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="subtitle" [innerHTML]="initialValue.secondaryCall"></div>
        <div
          class="description-area"
          *ngIf="initialValue.firstImageUrl || initialValue.description"
        >
          <img
            *ngIf="initialValue.firstImageUrl"
            class="first-image"
            [src]="initialValue.firstImageUrl"
            alt="logo da empresa"
          />
          <div
            *ngIf="initialValue.description"
            class="ol-template-slide-description"
            [innerHTML]="initialValue.description"
          ></div>
        </div>

        <div class="extra-info-area variable-wage" *ngIf="hiringBonus || iLP">
          <div class="infos">
            <div class="info first" *ngIf="iLP">
              <div class="info-icon">
                <svg-icon
                  class="hr-svg-icon clock"
                  src="/assets/icons/clock-plus.svg"
                ></svg-icon>
              </div>
              <div class="info-data">
                <span class="info-title">{{ "general.iLP" | translate }} </span>
                <span class="info-value">{{ iLP }}</span>
              </div>
            </div>

            <div class="info" *ngIf="hiringBonus">
              <div class="info-icon">
                <svg-icon
                  class="hr-svg-icon"
                  src="/assets/icons/money.svg"
                ></svg-icon>
              </div>
              <div class="info-data">
                <span class="info-title"
                  >{{ "general.hiringBonus" | translate }}
                </span>
                <span class="info-value">{{ hiringBonus }}</span>
              </div>
            </div>
          </div>
        </div>

        <div class="extra-info-area">
          <div class="title">
            {{ "offerLetter.template.aditionalInfos" | translate }}
          </div>
          <div class="infos">
            <div class="info" *ngIf="gestor">
              <div class="info-icon">
                <svg-icon
                  class="hr-svg-icon"
                  src="/assets/icons/person.svg"
                ></svg-icon>
              </div>
              <div class="info-data">
                <span class="info-title"
                  >{{ "general.manager" | translate }}:
                </span>
                <span class="info-value">{{ gestor }}</span>
              </div>
            </div>

            <div class="info" *ngIf="localTrabalho">
              <div class="info-icon">
                <svg-icon
                  class="hr-svg-icon"
                  src="/assets/icons/map-marker.svg"
                ></svg-icon>
              </div>
              <div class="info-data">
                <span class="info-title"
                  >{{ "offerLetter.template.workplace" | translate }}:
                </span>
                <span class="info-value">{{ localTrabalho }}</span>
              </div>
            </div>

            <div class="info" *ngIf="modeloTrabalho">
              <div class="info-icon">
                <svg-icon
                  class="hr-svg-icon"
                  src="/assets/icons/check-network.svg"
                ></svg-icon>
              </div>
              <div class="info-data">
                <span class="info-title"
                  >{{ "offerLetter.template.workModel" | translate }}:
                </span>
                <span class="info-value">{{ modeloTrabalho }}</span>
              </div>
            </div>

            <div class="info" *ngIf="cargaHoraria">
              <div class="info-icon">
                <svg-icon
                  class="hr-svg-icon"
                  src="/assets/icons/clock.svg"
                ></svg-icon>
              </div>
              <div class="info-data">
                <span class="info-title"
                  >{{ "offerLetter.template.workload" | translate }}:
                </span>
                <span class="info-value">{{ cargaHoraria }}</span>
              </div>
            </div>

            <div class="info">
              <div class="info-icon">
                <svg-icon
                  class="hr-svg-icon"
                  src="/assets/icons/calendar-range.svg"
                ></svg-icon>
              </div>
              <div class="info-data">
                <span class="info-title"
                  >{{
                    "offerLetter.template.expectedAdmissionDate" | translate
                  }}:
                </span>
                <span class="info-value">{{ dataAdmissao }}</span>
              </div>
            </div>
          </div>

          <div class="ol-info-box" [innerHTML]="initialValue.note"></div>
        </div>
      </div>
    </div>
  </div>
</div>
