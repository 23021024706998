<div class="rejected-reason-offer-container">
  <div class="hr-modal-close-button hr-tooltip-trigger" (click)="closeModal()">
    <svg-icon
      class="hr-svg-icon"
      src="../../../assets/icons/components/close-x.svg"
    ></svg-icon>

    <div class="hr-tooltip-content hr-mini-tooltip">
      <span>Fechar</span>
    </div>
  </div>

  <div class="modal-content">
    <span class="modal-title">Qual o motivo da sua recusa?</span>
    <span class="modal-description"
      >Nos informe o motivo da recusa da proposta</span
    >
    <form class="form-modal" [formGroup]="form">
      <hr-form-field-select
        [label]="'Informe o motivo da sua recusa'"
        [placeholder]="'Selecione o motivo da recusa'"
        [getList]="reasons"
        [openSelectSearchBar]="false"
        formControlName="reason"
      ></hr-form-field-select>
      <hr-form-field-textarea
        *ngIf="form.get('reason').value === 'Outros'"
        [label]="'Motivo da sua recusa'"
        [placeholder]="
          'Escreva aqui o motivo para recusar a proposta de trabalho'
        "
        [maxLength]="240"
        formControlName="description"
      ></hr-form-field-textarea>
      <div class="modal-btns">
        <button
          class="hr-button-primary"
          [disabled]="!form.get('reason').value"
          (click)="rejectOffer()"
        >
          <span>Enviar</span>
        </button>
      </div>
    </form>
  </div>
</div>
