import {
  Component,
  ElementRef,
  EventEmitter,
  forwardRef,
  Inject,
  Injector,
  INJECTOR,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Type,
  ViewChild,
} from '@angular/core';
import {
  ControlValueAccessor,
  FormArray,
  FormBuilder,
  FormControl,
  FormGroup,
  NgControl,
  NG_VALUE_ACCESSOR,
} from '@angular/forms';
import { BehaviorSubject, Subject } from 'rxjs';
import { delay, distinctUntilChanged, takeUntil } from 'rxjs/operators';
import equalsUtils from 'src/app/utils/equals.utils';
import * as uniqid from 'uniqid';
import arrayUtils from 'src/app/utils/array.utils';
import formUtils from '../form.utils';
import { SelectItem } from './form-field-select.model';

@Component({
  selector: 'hr-form-field-select',
  templateUrl: './form-field-select.component.html',
  styleUrls: ['./form-field-select.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormFieldSelectComponent),
    },
  ],
})
export class FormFieldSelectComponent
  implements ControlValueAccessor, OnInit, OnDestroy
{
  // tslint:disable: no-input-rename

  @Input() openSelectSearch = false;
  @Input() openSelectSearchBar = true;
  @Input() placeholder = '';
  @Input() label: string;
  @Input() id: string = uniqid();
  @Input() maxLength = undefined;
  @Input() minLength = undefined;
  @Input() searchPlaceholder = 'Buscar';
  @Input() labelSearch = 'Itens adicionados';
  @Input('list-label') listLabel = 'Banco de itens';
  @Input('no-items-added-label') noItemsAddedLabel = 'Nenhum item adicionado';
  @Input('no-items-found-label') noItemsFoundLabel = 'Nenhum item encontrado';
  @Input() multi = true;
  @Input() idSearch: string = uniqid();
  @Input() set getList(items: SelectItem[]) {
    if (!items) {
      return;
    }
    const newItems = arrayUtils.orderBy(items, 'ASC', 'label');
    if (!equalsUtils.arrayIsEquals(this.items, newItems)) {
      this.items = newItems;
      this.initForm();
      this.subscribeForm();
      this.updateView();
    }
  }
  @Input() set selectedItem(item) {
    if (item && this.items) {
      const value = this.items.find((i) => i.value === item);
      if (value) {
        this.setValue(value);
      }
    }
  }
  @Input() set value(value: string) {
    this.writeValue(value);
  }

  @Input() set disabled(isDisabled: boolean) {
    this.setDisabledState(isDisabled);
  }

  @Input() readonly = false;
  @Input('custom-errors') customErrorList: { [error: string]: string };
  @Output() blur = new EventEmitter<FocusEvent>();
  @ViewChild('formSelect') formSelectView: ElementRef<HTMLElement>;
  ngControl: NgControl;
  control = new FormControl();

  private onDestroy$ = new Subject<void>();
  formGroup: FormGroup;
  filteredItems$ = new BehaviorSubject<SelectItem[]>([]);
  searchFormControl = new FormControl(null);
  items: SelectItem[];
  itemSelected: string;

  get formArray() {
    return this.formGroup.get('items') as FormArray;
  }

  onChange = (_: any) => {};
  onTouched = (_: any) => {};

  @Input() set valueSearch(value: string[]) {
    this.writeValue(value);
  }

  @Input() set disabledSearch(isDisabled: boolean) {
    this.setDisabledState(isDisabled);
  }

  constructor(
    private formBuilder: FormBuilder,
    @Inject(INJECTOR) private injector: Injector
  ) {}

  ngOnInit() {
    this.initForm();
    this.ngControl = this.injector.get<NgControl>(NgControl as Type<NgControl>);

    if (this.itemSelected === '') {
      this.itemSelected = this.placeholder;
    }
  }

  getError() {
    if (!this.ngControl.errors) {
      return '';
    }

    return formUtils.getError(this.ngControl.control, this.customErrorList);
  }

  selectItem(item) {
    this.setValue(item);
    this.openSelectSearch = false;
  }

  private subscribeForm() {
    this.searchFormControl.valueChanges
      .pipe(delay(300), distinctUntilChanged(), takeUntil(this.onDestroy$))
      .subscribe((search) => {
        this.search(search);
      });

    this.updateItemSelected(this.control.value);

    this.control.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((item) => {
        this.updateItemSelected(item);
      });
  }

  private updateItemSelected(value: string) {
    const itemFiltered = this.items.filter((label) => label.value === value);
    if (itemFiltered || itemFiltered !== undefined) {
      this.itemSelected = itemFiltered[0] ? itemFiltered[0].label : null;
    }
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  closeSelect() {
    if (this.openSelectSearch === false) {
      return;
    }

    this.openSelectSearch = false;
  }

  updateView() {
    this.search(this.searchFormControl.value);
  }

  private search(filter: string) {
    let result;
    this.filteredItems$.next(
      (result = arrayUtils.filter(this.items, filter, ['label', 'description']))
    );

    if (result.length === 0) {
      this.searchFormControl.setErrors({ notFound: true });
    } else {
      this.searchFormControl.setErrors(null);
    }
  }

  private initForm() {
    this.formGroup = this.formBuilder.group({
      items: this.formBuilder.array(
        this.items
          ? this.items.map((item) => {
              return this.formBuilder.group({
                value: [item.value],
                label: [
                  {
                    value: item.label,
                    description: item.description,
                    disabled: true,
                  },
                ],
              });
            })
          : []
      ),
    });
    this.filteredItems$.next(this.items);
  }

  openSelect() {
    this.openSelectSearch = this.openSelectSearch === false;
  }

  setValue(item: SelectItem) {
    this.openSelectSearch = false;
    if (this.control.value !== item.label) {
      this.writeValue(item.value);
      this.itemSelected = item.label;
    }
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;

    this.control.valueChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(this.onChange);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;

    this.control.statusChanges
      .pipe(takeUntil(this.onDestroy$))
      .subscribe(this.onTouched);
    this.blur.pipe(takeUntil(this.onDestroy$)).subscribe(this.onTouched);
  }

  writeValue(obj: any): void {
    this.control.setValue(obj);
  }
}
