<div class="hr-slide-area">
  <div class="ol-template-back-bar"></div>
  <div
    class="ol-template-slide-data"
    [ngStyle]="{
      'background-image': 'url(' + initialValue.backgroundImageUrl + ')'
    }"
  >
    <div class="slide-data-area">
      <img
        class="logo-image"
        [src]="initialValue.logoImageUrl"
        alt="logo da empresa"
      />

      <div
        class="ol-template-slide-title"
        [innerHTML]="initialValue.title"
      ></div>
      <div
        class="offer-description"
        [innerHTML]="initialValue.description"
      ></div>
      <div class="offer-data">
        <div class="position-infos">
          <div class="position-info">
            <mat-icon>work_outline</mat-icon>
            <span class="title"
              >{{ "offerLetter.template.position" | translate }}:</span
            >
            <span class="description" [innerHTML]="cargo"></span>
          </div>

          <div class="position-info">
            <mat-icon>attach_money</mat-icon>
            <span class="title"
              >{{ "offerLetter.template.monthlyWage" | translate }}:</span
            >
            <span>
              {{ "general.currencyMark" | translate
              }}{{ getCurrency(salario) }}</span
            >
          </div>
        </div>
      </div>
      <div class="actions-area">
        <button class="offer-button-area primary" [disabled]="disabledBttns" (click)="onAcceptOffer()">
          <span>{{ "offerLetter.template.acceptOffer" | translate }}</span>
        </button>
        <button
          *ngIf="propostaService.getView().status !== aceito"
          class="offer-button-area secondary"
          (click)="openModalRejectOffer()"
          [disabled]="disabledBttns"
        >
          <span>{{ "offerLetter.template.rejectOffer" | translate }}</span>
        </button>
        <!-- todo: depois -->
        <!-- <div class="offer-button-area icon">
          <span>{{ "offerLetter.template.wantToNegociate" | translate }}</span>
          <svg-icon
            class="hr-svg-icon"
            src="/assets/icons/shared/arrow-full.svg"
          ></svg-icon>
        </div> -->
      </div>

      <div class="ol-info-box" *ngIf="dataLimite">
        {{ "offerLetter.template.offerIsValid" | translate }} até
        <span class="title" [innerHTML]="dataLimite"></span>
      </div>
    </div>
  </div>
</div>
