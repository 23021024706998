import {
  Component,
  EventEmitter,
  forwardRef,
  Inject,
  Injector,
  INJECTOR,
  Input,
  OnDestroy,
  OnInit,
  Output,
  Type,
} from '@angular/core';
import { ControlValueAccessor, FormControl, NgControl, NG_VALUE_ACCESSOR } from '@angular/forms';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import * as uniqid from 'uniqid';
import formUtils from '../form.utils';

@Component({
  selector: 'hr-form-field-input',
  templateUrl: './form-field-input.component.html',
  styleUrls: ['./form-field-input.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormFieldInputComponent),
    },
  ],
})
export class FormFieldInputComponent implements ControlValueAccessor, OnInit, OnDestroy {
  // tslint:disable: no-input-rename
  @Input() placeholder = '';
  @Input() label: string;
  @Input() id: string = uniqid();
  @Input() maxLength = undefined;
  @Input() minLength = undefined;
  @Input() set value(value: string) {
    this.writeValue(value);
  }
  @Input() set disabled(isDisabled: boolean) {
    this.setDisabledState(isDisabled);
  }
  @Input() readonly = false;
  @Input('custom-errors') customErrorList: { [error: string]: string };
  @Output() blur = new EventEmitter<FocusEvent>();
  @Output() keyup = new EventEmitter<KeyboardEvent>();
  @Output() focus = new EventEmitter<FocusEvent>();

  ngControl: NgControl;
  control = new FormControl();
  focused = false;

  private onDestroy$ = new Subject<void>();

  onChange = (_: any) => {};
  onTouched = (_: any) => {};

  constructor(@Inject(INJECTOR) private injector: Injector) {}

  ngOnInit() {
    this.ngControl = this.injector.get<NgControl>(NgControl as Type<NgControl>);
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
  }

  writeValue(value: any): void {
    this.control.setValue(value);
  }

  registerOnChange(fn: any): void {
    this.onChange = fn;

    this.control.valueChanges.pipe(takeUntil(this.onDestroy$)).subscribe(this.onChange);
  }

  registerOnTouched(fn: any): void {
    this.onTouched = fn;

    this.control.statusChanges.pipe(takeUntil(this.onDestroy$)).subscribe(this.onTouched);
    this.blur.pipe(takeUntil(this.onDestroy$)).subscribe(this.onTouched);
  }

  setDisabledState(isDisabled: boolean): void {
    isDisabled ? this.control.disable() : this.control.enable();
  }

  getError() {
    if (!this.ngControl.errors) {
      return '';
    }

    return formUtils.getError(this.ngControl.control, this.customErrorList);
  }
}
