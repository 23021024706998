<!-- <hr-carta-oferta-header></hr-carta-oferta-header> -->
<!-- <section class="hr-page"> -->
<ng-container *ngIf="template?.templateType; else loading">
  <hr-dynamic
    *ngIf="template"
    [template]="template"
    [slideIndex]="slideIndex"
  ></hr-dynamic>
</ng-container>

<ng-template #loading>
  <div style="height: 100vh">
    <div class="hr-loader-area" style="height: inherit">
      <div class="loader">Loading...</div>
    </div>
  </div>
</ng-template>
<!-- </section> -->
