import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { firstValueFrom } from 'rxjs';
import {
  grupoBenesView,
  olBenefitModel,
} from '../models/pacote-beneficio.models';
import { PropostaSalarialView } from '../models/proposta-salarial.model';
import { PropostaSalarialService } from './proposta-salarial.service';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class BeneficioService {
  private dominioId: string;
  pacoteView: PropostaSalarialView = {};
  private pacoteBenesPath: string;

  private allBenefits: { [benefitTypeId: string]: olBenefitModel[] };
  private benefits: { [benefitTypeId: string]: string[] };

  constructor(
    private firestore: AngularFirestore,
    private authService: AuthService,
    propostaService: PropostaSalarialService
  ) {
    this.dominioId = this.authService.dominioId;
    this.pacoteBenesPath = propostaService.getView().pacoteBenes;
    this.allBenefits = {};
    this.benefits = {};
  }

  async load() {
    const pacote = await firstValueFrom(
      this.firestore.doc(`${this.pacoteBenesPath}`).get()
    );

    if (!pacote.exists) {
      return;
    }
    const pac = pacote.data() as any;

    Object.keys(pac.benefits).forEach((grupo) => {
      this.allBenefits[grupo] = [];
      this.benefits[grupo] = [];
      const benes = pac.benefits[grupo];

      for (const bene of benes) {
        this.benefits[grupo].push(bene.value);
      }
    });
  }

  async loadBenes() {
    return this.firestore
      .collection<olBenefitModel>(`empresaMae/${this.dominioId}/olBenefit`)
      .ref // .where('status', '==', 'ativo')
      .get()
      .then((bns) => {
        if (bns.empty) {
          return null;
        }
        const listGrupos = [];
        Object.keys(this.benefits).forEach((e) => {
          listGrupos.push(e);
        });

        bns.docs.forEach((p) => {
          const bene: olBenefitModel = p.data();
          if (bene.status !== 'ativo') {
            return;
          }

          if (
            listGrupos.indexOf(bene.benefitTypeId) !== -1 &&
            this.benefits[bene.benefitTypeId].includes(p.id)
          ) {
            this.allBenefits[bene.benefitTypeId].push(bene);
          }
        });
      });
  }

  async getGrupos() {
    await this.load();
    await this.loadBenes();

    const grupos = [];

    return this.firestore
      .collection<grupoBenesView>(`empresaMae/${this.dominioId}/olBenefitType`)
      .ref // .where('status', '==', 'ativo')
      .get()
      .then((types) => {
        if (types.empty) {
          return null;
        }
        types.docs.forEach((p) => {
          const grupo: grupoBenesView = p.data();

          if (grupo.status !== 'ativo') {
            return;
          }

          const listGrupos = [];
          Object.keys(this.benefits).forEach((e) => {
            listGrupos.push(e);
          });

          if (listGrupos.indexOf(p.ref.id) !== -1) {
            grupos.push({
              id: p.ref.id,
              ...grupo,
              benes: this.allBenefits[p.ref.id],
            });
          }
        });
        return grupos;
      });
  }
}
