<div class="hr-slide-area">
  <div class="ol-template-back-bar"></div>
  <div
    class="ol-template-back-bar mobile"
    [ngStyle]="{
      'background-image': initialValue.backgroundImageUrl
        ? 'url(' + initialValue.backgroundImageUrl + ')'
        : null
    }"
  ></div>
  <div class="ol-template-slide-data">
    <div
      class="background-image"
      [ngStyle]="{
        'background-image': initialValue.backgroundImageUrl
          ? 'url(' + initialValue.backgroundImageUrl + ')'
          : null
      }"
    ></div>
    <div class="slide-data-area">
      <div class="ol-template-slide-info">
        <div
          class="ol-template-slide-title"
          [innerHTML]="initialValue.title"
        ></div>
        <div class="offer-description">
          <div class="icon-area">
            <svg-icon
              class="hr-svg-icon"
              src="/assets/icons/modules/carta-oferta/suitcase.svg"
            ></svg-icon>
          </div>
          <div class="offer-data">
            <div class="title">
              {{ "offerLetter.template.jobPositionTitle" | translate }}
            </div>
            <div class="description">
              {{ cargo }}
            </div>
          </div>
        </div>
      </div>
      <div class="offer-button-area" (click)="goToNextSlide()">
        <span>{{ "offerLetter.template.openOfferLabel" | translate }}</span>
        <svg-icon
          class="hr-svg-icon"
          src="/assets/icons/shared/arrow-full.svg"
        ></svg-icon>
      </div>
    </div>
  </div>
</div>
