import { Component, Inject, OnInit } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material/dialog';
import { IVideoObject } from 'src/app/models/video.model';
import { ImageService } from 'src/app/services/image.service';

@Component({
  selector: 'app-beneficio-detalhe',
  templateUrl: './beneficio-detalhe.component.html',
  styleUrls: ['./beneficio-detalhe.component.scss'],
  providers:[ImageService]
})
export class BeneficioDetalheComponent implements OnInit {
  videoUrl: string;
  constructor(
    @Inject(MAT_DIALOG_DATA)
    public data: {
      description: string;
      image: string;
      name: string;
      video: IVideoObject;
      videoPath: string;
    },
    private dialogRef: MatDialogRef<BeneficioDetalheComponent>,
    private imageService: ImageService
  ) {
    this.getVideoUrl();
  }

  ngOnInit() {}

  async getVideoUrl() {
    if (this.data && this.data.videoPath) {
      this.videoUrl = await this.imageService.getFile(this.data.videoPath);
    }
  }
  closeModal() {
    this.dialogRef.close();
  }
}
