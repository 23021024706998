import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SlideBaseComponent } from 'src/app/shared/slider/slide/slide-base.component';
import { ITemplateAbout } from 'src/app/models/templates.model';
import { TranslateService } from '@ngx-translate/core';

@Component({
  selector: 'hr-dynamic-about',
  templateUrl: './dynamic-about.component.html',
  styleUrls: ['./dynamic-about.component.scss'],
  providers: [FormBuilder],
})
export class DynamicAboutComponent extends SlideBaseComponent {
  override initialValue: ITemplateAbout;

  constructor(
    private formBuilder: FormBuilder,
    public translate: TranslateService
  ) {
    super();
  }

  protected onInit(): void {}
  protected onDestroy(): void {}

  protected initForm(): void {
    this.form = this.formBuilder.group({
      slideName: [null, [Validators.required, Validators.maxLength(20)]],
      title: [null, [Validators.required, Validators.maxLength(40)]],
      description: [null, [Validators.required, Validators.maxLength(1500)]],
      firstImage: [null, [Validators.required]],
      firstImageUrl: [null, [Validators.required]],
      secondImage: [null, [Validators.required]],
      secondImageUrl: [null, [Validators.required]],
    });
  }
}
