class EqualsUtils {
  public objectIsDeepEquals(a: any, b: any) {
    if (a === b) {
      return true;
    }

    if (typeof a !== 'object' || typeof b !== 'object' || a == null || b == null) {
      return false;
    }

    const keysA = Object.keys(a);
    const keysB = Object.keys(b);

    if (keysA.length !== keysB.length) {
      return false;
    }

    let result = true;

    keysA.forEach(key => {
      if (!keysB.includes(key)) {
        result = false;
      }

      if ((typeof a[key] === 'function' || typeof b[key] === 'function') && a[key].toString() !== b[key].toString()) {
        result = false;
      }

      if ((typeof a[key] === typeof [] || typeof b[key] === typeof []) && !this.arrayIsEquals(a[key], b[key])) {
        result = false;
      }

      if (!this.objectIsDeepEquals(a[key], b[key])) {
        result = false;
      }
    });

    return result;
  }

  public arrayIsEquals(a: any[], b: any[]) {
    if (a === b) {
      return true;
    }
    if (a == null || b == null) {
      return false;
    }
    if (a.length !== b.length) {
      return false;
    }

    for (let i = 0; i < a.length; ++i) {
      if (!this.objectIsDeepEquals(a[i], b[i])) {
        return false;
      }
    }

    return true;
  }
}
export default new EqualsUtils();
