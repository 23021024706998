import { Component, OnDestroy, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialogRef } from '@angular/material/dialog';
import { TranslateService } from '@ngx-translate/core';
import { Subject, takeUntil } from 'rxjs';

@Component({
  selector: 'hr-rejected-reason-alert',
  templateUrl: './rejected-reason-alert.component.html',
  styleUrls: ['./rejected-reason-alert.component.scss'],
  providers: [FormBuilder],
})
export class RejectedReasonAlertComponent implements OnInit, OnDestroy {
  form: FormGroup;
  showDesc = false;
  reasons = [
    {
      label: this.getTranslate(
        'offerLetter.template.rejectedReasons.anotherJob'
      ),
      value: this.getTranslate(
        'offerLetter.template.rejectedReasons.anotherJob'
      ),
    },
    {
      label: this.getTranslate(
        'offerLetter.template.rejectedReasons.proposalBelow'
      ),
      value: this.getTranslate(
        'offerLetter.template.rejectedReasons.proposalBelow'
      ),
    },
    {
      label: this.getTranslate(
        'offerLetter.template.rejectedReasons.unattractiveBenefits'
      ),
      value: this.getTranslate(
        'offerLetter.template.rejectedReasons.unattractiveBenefits'
      ),
    },
    {
      label: this.getTranslate(
        'offerLetter.template.rejectedReasons.personalReasons'
      ),
      value: this.getTranslate(
        'offerLetter.template.rejectedReasons.personalReasons'
      ),
    },
    {
      label: this.getTranslate(
        'offerLetter.template.rejectedReasons.dontChangeJobs'
      ),
      value: this.getTranslate(
        'offerLetter.template.rejectedReasons.dontChangeJobs'
      ),
    },
    {
      label: this.getTranslate(
        'offerLetter.template.rejectedReasons.othersReason'
      ),
      value: this.getTranslate(
        'offerLetter.template.rejectedReasons.othersReason'
      ),
    },
  ];
  private onDestroy$ = new Subject();
  constructor(
    private formBuilder: FormBuilder,
    private translateService: TranslateService,
    private dialogRef: MatDialogRef<RejectedReasonAlertComponent>
  ) {}

  ngOnInit() {
    this.initForm();
  }

  ngOnDestroy() {
    this.onDestroy$.next(null);
    this.onDestroy$.complete();
  }

  initForm() {
    this.form = this.formBuilder.group({
      reason: [null, [Validators.required]],
      description: [null, [Validators.required, Validators.maxLength(240)]],
    });
    this.subscribeForm();
  }

  closeModal() {
    this.dialogRef.close();
  }

  rejectOffer() {
    let reasonForm = this.form.get('reason').value;
    let descForm = this.form.get('description').value;
    let description = null;
    if (reasonForm === 'Outros') {
      if (descForm) {
        description = descForm;
      } else {
        return this.form.get('description').markAsTouched();
      }
    }
    this.dialogRef.close({ reason: reasonForm, description });
  }

  getTranslate(text: string) {
    return this.translateService.instant(text);
  }

  private subscribeForm() {
    this.form
      .get('reason')
      .valueChanges.pipe(takeUntil(this.onDestroy$))
      .subscribe((reason) => {
        if (reason === 'Outros') {
          this.form
            .get('description')
            .setValidators([Validators.required, Validators.maxLength(240)]);
          this.form.get('description').setValue(null);
        } else {
          this.form.get('description').setValidators(null);
        }
      });
  }
}
