import { NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './modules/login/login.component';
import { SignupService } from './services/signup.service';
import { SignupComponent } from './modules/signup/signup.component';
import { TemplateResolver } from './modules/template/dynamic/template.resolver';
import { TemplateComponent } from './modules/template/template.component';
import { SignInPreviewService } from './services/sign-in-preview.service';

const routes: Routes = [
  // { path: '', component: LoginComponent },
  { path: 'validar/:userId', component: LoginComponent },
  // { path: 'login', component: LoginComponent },
  {
    path: 'proposta/:userId',
    component: TemplateComponent,
    resolve: { proposta: TemplateResolver },
  },
  {
    path: 'proposta/:userId/:slideIndex',
    component: TemplateComponent,
    resolve: { proposta: TemplateResolver },
  },
  {
    path: 'login',
    component: SignupComponent,
    canActivate: [SignupService],
  },
  {
    path: 'email',
    component: SignupComponent,
    canActivate: [SignupService],
  },

  {
    path: 'preview',
    canActivate: [SignInPreviewService],
  },
  {
    path: 'preview/:userId',
    component: TemplateComponent,
    resolve: { proposta: TemplateResolver },
  },
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule],
})
export class AppRoutingModule {}
