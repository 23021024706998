import { Component } from '@angular/core';
import { FormArray, FormBuilder, Validators } from '@angular/forms';
import { ITemplateGroups } from 'src/app/models/templates.model';

import { SlideBaseComponent } from 'src/app/shared/slider/slide/slide-base.component';

@Component({
  selector: 'hr-dynamic-groups',
  templateUrl: './dynamic-groups.component.html',
  styleUrls: ['./dynamic-groups.component.scss'],
  providers: [FormBuilder],
})
export class DynamicGroupsComponent extends SlideBaseComponent {
  override initialValue: ITemplateGroups;
  selectedGroup = 0;

  constructor(private formBuilder: FormBuilder) {
    super();
  }

  protected onInit(): void {}
  protected onDestroy(): void {}

  protected initForm(): void {
    if (this.canEdit) {
      this.form = this.formBuilder.group({
        slideName: [null, [Validators.required, Validators.maxLength(20)]],
        title: [null, [Validators.required, Validators.maxLength(100)]],
        groups: this.formBuilder.array([]),
      });

      if (this.initialValue.groups) {
        this.initialValue.groups.forEach((_) => this.addNewGroup());
      }
    }
  }

  addNewGroup(event?) {
    if (event) {
      event.stopPropagation();
    }

    const groupsCtrl = this.form.get('groups') as FormArray;
    groupsCtrl.push(this.getNewGroup());
  }

  deleteGroup(groupIndex: number) {
    this.groups.removeAt(groupIndex);
  }

  get groups() {
    return this.form.get('groups') as FormArray;
  }

  selectGroup(index: number) {
    this.selectedGroup = index;
  }

  private getNewGroup() {
    return this.formBuilder.group({
      name: [null, [Validators.required, Validators.maxLength(40)]],
      description: [null, [Validators.required, Validators.maxLength(800)]],
      groupImage: [null, [Validators.required]],
      groupImageUrl: [null, [Validators.required]],
    });
  }
}
