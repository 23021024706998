import { Component, forwardRef, Inject, INJECTOR, Injector } from '@angular/core';
import { NG_VALUE_ACCESSOR } from '@angular/forms';
import { FormFieldInputComponent } from '../form-field-input/form-field-input.component';

@Component({
  selector: 'hr-form-field-textarea',
  templateUrl: './form-field-textarea.component.html',
  styleUrls: ['./form-field-textarea.component.scss'],
  providers: [
    {
      provide: NG_VALUE_ACCESSOR,
      multi: true,
      useExisting: forwardRef(() => FormFieldTextareaComponent),
    },
  ],
})
export class FormFieldTextareaComponent extends FormFieldInputComponent {
  constructor(@Inject(INJECTOR) injector: Injector) {
    super(injector);
  }
}
