import { Component } from '@angular/core';
import { FormBuilder, FormControl, Validators } from '@angular/forms';
import { SlideBaseComponent } from 'src/app/shared/slider/slide/slide-base.component';
import { ITemplateWelcome } from 'src/app/models/templates.model';
import { PropostaSalarialService } from 'src/app/services/proposta-salarial.service';

@Component({
  selector: 'hr-dynamic-welcome',
  templateUrl: './dynamic-welcome.component.html',
  styleUrls: ['./dynamic-welcome.component.scss'],
})
export class DynamicWelcomeComponent extends SlideBaseComponent {
  videoCtrl: FormControl;
  override initialValue: ITemplateWelcome;
  nomeProfissional: string = '';
  formatedTitle: string;

  constructor(
    private formBuilder: FormBuilder,
    private propostaService: PropostaSalarialService
  ) {
    super();
    this.nomeProfissional = propostaService.getView().nomeCompleto;
    // console.log('--this.nomeProfissional', this.nomeProfissional);
    // this.formatedTitle = this.initialValue.title.replace(
    //   '#{nome_profissional}',
    //   this.nomeProfissional
    // );
    // console.log('--formatedTitle', this.formatedTitle);
  }

  protected onInit() {}

  protected onDestroy() {}

  protected initForm() {
    // console.log('initing form');
    this.form = this.formBuilder.group({
      slideName: [null, [Validators.required, Validators.maxLength(20)]],
      title: [null, [Validators.required, Validators.maxLength(40)]],
      quotes: [null, [Validators.required, Validators.maxLength(200)]],
      description: [null, [Validators.required, Validators.maxLength(1500)]],
      signature: [null, [Validators.required, Validators.maxLength(40)]],
      videoObject: [null, [Validators.required, Validators.maxLength(40)]],
    });
  }
}
