import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SlideBaseComponent } from 'src/app/shared/slider/slide/slide-base.component';
import { ITemplateOffer } from 'src/app/models/templates.model';
import { PropostaSalarialService } from 'src/app/services/proposta-salarial.service';

@Component({
  selector: 'hr-dynamic-offer',
  templateUrl: './dynamic-offer.component.html',
  styleUrls: ['./dynamic-offer.component.scss'],
  providers: [FormBuilder],
})
export class DynamicOfferComponent extends SlideBaseComponent {
  override initialValue: ITemplateOffer;

  cargo: string;

  constructor(
    private formBuilder: FormBuilder,
    private propostaService: PropostaSalarialService
  ) {
    super();
    this.cargo = this.propostaService.getView().cargo;
  }

  protected onInit(): void {}
  protected onDestroy(): void {}

  protected initForm(): void {
    this.form = this.formBuilder.group({
      slideName: [null, [Validators.required, Validators.maxLength(20)]],
      title: [null, [Validators.required, Validators.maxLength(80)]],
      backgroundImage: [null, [Validators.required]],
      backgroundImageUrl: [null, [Validators.required]],
    });
  }
}
