import { CommonModule } from '@angular/common';
import { HttpClient, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { TemplateComponent } from '../modules/template/template.component';
import { DynamicComponent } from '../modules/template/dynamic/dynamic.component';
import { DynamicCoverComponent } from '../modules/template/dynamic/dynamic-cover/dynamic-cover.component';
import { DynamicWelcomeComponent } from '../modules/template/dynamic/dynamic-welcome/dynamic-welcome.component';
import { DynamicAboutComponent } from '../modules/template/dynamic/dynamic-about/dynamic-about.component';
import {
  TranslateLoader,
  TranslateModule,
  TranslateService,
} from '@ngx-translate/core';
import { TranslateHttpLoader } from '@ngx-translate/http-loader';
import { SliderComponent } from './slider/slider.component';
import { SlideComponent } from './slider/slide/slide.component';
import { PaddingNumberPipe } from '../pipes/padding-number.pipe';
import { LoginComponent } from '../modules/login/login.component';
import { MaterialModule } from './material/material.module';
import { ClockPipe } from '../pipes/clock.pipe';
import { NgxMaskModule } from 'ngx-mask';
import { SlideDirective } from './slider/slide/slide.directive';
import { VarDirective } from '../directives/var.directive';
import { ResizeTextareaDirective } from '../directives/resize-textarea.directive';
import { AngularSvgIconModule, SvgIconRegistryService } from 'angular-svg-icon';
import { ListSliderComponent } from './list-slider/list-slider.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { ErrorService } from '../services/error.service';
import { AlertComponent } from '../components/alert/alert.component';
import { DynamicGroupsComponent } from '../modules/template/dynamic/dynamic-groups/dynamic-groups.component';
import { TemplateResolver } from '../modules/template/dynamic/template.resolver';
import { DynamicOfferComponent } from '../modules/template/dynamic/dynamic-offer/dynamic-offer.component';
import { DynamicOfferDetailComponent } from '../modules/template/dynamic/dynamic-offer-details/dynamic-offer-detail.component';
import { DynamicBenefitsComponent } from '../modules/template/dynamic/dynamic-benefits/dynamic-benefits.component';
import { DynamicQuestionComponent } from '../modules/template/dynamic/dynamic-question/dynamic-question.component';
import { VideoPlayerComponent } from './video-player/video-player.component';
import { FormFieldInputComponent } from './components/form/form-field-input/form-field-input.component';
import { FormFieldSelectComponent } from './components/form/form-field-select/form-field-select.component';
import { FormFieldTextareaComponent } from './components/form/form-field-textarea/form-field-textarea.component';
import { ClickOutsideModule } from '../directives/click-outside/click-outside.module';

export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http, './assets/i18n/', '.json');
}

@NgModule({
  entryComponents: [
    AlertComponent,
    DynamicCoverComponent,
    DynamicWelcomeComponent,
    DynamicAboutComponent,
    DynamicBenefitsComponent,
    DynamicGroupsComponent,
    DynamicOfferComponent,
    DynamicOfferDetailComponent,
    DynamicQuestionComponent,
  ],
  declarations: [
    AlertComponent,
    ClockPipe,
    DynamicComponent,
    DynamicCoverComponent,
    DynamicWelcomeComponent,
    DynamicAboutComponent,
    DynamicBenefitsComponent,
    DynamicGroupsComponent,
    DynamicOfferComponent,
    DynamicOfferDetailComponent,
    DynamicQuestionComponent,
    ListSliderComponent,
    LoginComponent,
    SlideComponent,
    SlideDirective,
    SliderComponent,
    PaddingNumberPipe,
    ResizeTextareaDirective,
    TemplateComponent,
    VarDirective,
    VideoPlayerComponent,
    FormFieldInputComponent,
    FormFieldSelectComponent,
    FormFieldTextareaComponent,
  ],
  imports: [
    AngularSvgIconModule,

    CommonModule,
    FormsModule,
    HttpClientModule,
    MaterialModule,
    NgxMaskModule.forRoot(),
    ReactiveFormsModule,
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient],
      },
    }),
    ClickOutsideModule,
  ],
  providers: [
   // { provide: MaskPipe, useClass: MaskPipe },
    TranslateService,
    ErrorService,
    TemplateResolver,
    SvgIconRegistryService,
  ],
  exports: [
    AngularSvgIconModule,
    ClockPipe,
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    HttpClientModule,
    ListSliderComponent,
    MaterialModule,
    NgxMaskModule,
    PaddingNumberPipe,
    ResizeTextareaDirective,
    SlideComponent,
    SliderComponent,
    TranslateModule,
    VarDirective,
    VideoPlayerComponent,
    FormFieldInputComponent,
    FormFieldSelectComponent,
    FormFieldTextareaComponent,
    ClickOutsideModule,
  ],
})
export class SharedModule {}
