import { NgModule } from '@angular/core';
import { BrowserModule } from '@angular/platform-browser';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';

import { AngularFireModule } from '@angular/fire/compat';
import { AngularFireDatabaseModule } from '@angular/fire/compat/database';
import { AngularFirestoreModule } from '@angular/fire/compat/firestore';
import { AngularFireStorageModule } from '@angular/fire/compat/storage';
import { AngularFireAnalyticsModule } from '@angular/fire/compat/analytics';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';

import { environment } from 'src/environments/environment';

import { SharedModule } from './shared/shared.module';
import { AngularSvgIconModule } from 'angular-svg-icon';
import { BeneficioDetalheComponent } from './components/beneficio-detalhe/beneficio-detalhe.component';
import { SignupComponent } from './modules/signup/signup.component';
import { DynamicAcceptedOfferComponent } from './modules/template/dynamic/dynamic-accepted-offer/dynamic-accepted-offer.component';
import { DynamicRejectedOfferComponent } from './modules/template/dynamic/dynamic-rejected-offer/dynamic-rejected-offer.component';
import { RejectedAlertComponent } from './components/rejected-alert/rejected-alert.component';
import { RejectedReasonAlertComponent } from './components/rejected-alert/rejected-reason-alert/rejected-reason-alert.component';

@NgModule({
  entryComponents: [],
  declarations: [
    AppComponent,
    BeneficioDetalheComponent,
    SignupComponent,
    DynamicAcceptedOfferComponent,
    DynamicRejectedOfferComponent,
    RejectedAlertComponent,
    RejectedReasonAlertComponent,
  ],
  imports: [
    AngularFireDatabaseModule,
    AngularFireModule.initializeApp(environment.firebase),
    AngularFirestoreModule,
    AngularFireStorageModule,
    AngularFireAnalyticsModule,
    AngularSvgIconModule.forRoot(),
    AppRoutingModule,
    BrowserAnimationsModule,
    BrowserModule,
    SharedModule,
  ],
  providers: [],
  bootstrap: [AppComponent],
  exports: [BrowserModule],
})
export class AppModule {}
