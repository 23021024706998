import {
  Component,
  EventEmitter,
  Input,
  OnDestroy,
  OnInit,
  Output,
} from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { formGroupIterator } from 'src/app/utils/form.helpers';
import { ISlide } from './slider.model';
import { PropostaSalarialService } from '../../services/proposta-salarial.service';
import { CO_STATUS_CONST } from 'src/app/utils/utils';
import { Subject, takeUntil } from 'rxjs';

interface ISlideButton extends ISlide {
  index: number;
}

@Component({
  selector: 'hr-slider',
  templateUrl: './slider.component.html',
  styleUrls: ['./slider.component.scss'],
})
export class SliderComponent implements OnInit, OnDestroy {
  @Input() slides: ISlide[];
  @Input() finishLocked: boolean;
  @Input() canFinish = true;
  @Input() slideIndex: string;
  @Output() formEmitter = new EventEmitter();
  @Output() finishSlidesEmitter = new EventEmitter();
  forms: FormGroup[];
  currentIndex: number;
  // noButtonSlides: number[];
  slidesButton: ISlideButton[];

  noButtonSlideType: string[];

  private onDestroy$ = new Subject();

  constructor(private propostaService: PropostaSalarialService) {
    this.noButtonSlideType = [
      'cover',
      'offer-detail',
      'accepted-offer',
      'rejected-offer',
    ];
  }

  ngOnInit() {
    this.slidesButton = [];
    this.subscribeStatus();
    this.updateSlideButtons();
  }

  ngOnDestroy() {
    this.onDestroy$.complete();
  }

  getCurrentIconType(type: string) {
    if (['accepted-offer', 'rejected-offer'].includes(type)) {
      return 'question';
    }
    return type;
  }

  getForm(index: number, value: FormGroup) {
    this.forms[index] = value;
    this.updateSlide(this.forms[index], index);

    if (!this.forms.includes(null)) {
      this.formEmitter.emit(this.forms);
    }
  }

  goBack(index?: number) {
    if (index === 6) {
      this.goTo(this.currentIndex - 2, true);
    } else {
      this.goTo(this.currentIndex - 1, true);
    }
  }

  goForward(index?: number) {
    if (index === 4) {
      this.goTo(this.currentIndex + 2);
    } else {
      this.goTo(this.currentIndex + 1);
    }
  }

  goTo(index: number, goBack = false) {
    let nextSlideIndex = index;

    let slideEnabled: ISlideButton;
    while (!slideEnabled && nextSlideIndex !== 5) {
      slideEnabled = this.slidesButton.find(
        (slide) => slide.index === nextSlideIndex
      );

      if (!slideEnabled) {
        goBack ? nextSlideIndex-- : nextSlideIndex++;
      }

      if (goBack ? nextSlideIndex < 0 : nextSlideIndex >= this.forms.length) {
        return;
      }
    }

    this.currentIndex = nextSlideIndex;

    formGroupIterator(
      this.forms[this.currentIndex],
      (field: AbstractControl) => {
        field.markAsTouched();
        field.markAsDirty();
      }
    );

    if (index === this.slides.length && !this.finishLocked) {
      this.finishSlidesEmitter.emit(this.forms);
    }
  }

  onNextSlide(event) {
    if (event === 2) {
      this.goTo(this.currentIndex + 2);
    } else {
      this.goForward();
    }
  }

  private subscribeStatus() {
    this.propostaService
      .getStatus$()
      .pipe(takeUntil(this.onDestroy$))
      .subscribe((status) => {
        this.updateSlideHiddenButtons(status);
        this.updateSlideButtons(true);
      });
  }

  private updateSlideButtons(reload = false) {
    let i = 0;

    if (reload) {
      let newLastSlide: ISlideButton;
      for (let lastIndex = this.slides.length - 1; lastIndex > 0; lastIndex--) {
        const slide = this.slides[lastIndex];
        if (this.noButtonSlideType.indexOf(slide.initialValue.type) === -1) {
          console.log('new slide!', slide);
          newLastSlide = { index: lastIndex, ...slide };
          break;
        }
      }

      this.slidesButton[this.slidesButton.length - 1] = newLastSlide;
    } else {
      this.slidesButton = [];
      for (const s of this.slides) {
        if (this.noButtonSlideType.indexOf(s.initialValue.type) === -1) {
          this.slidesButton.push({ index: i, ...s });
        }
        i++;
      }

      if (this.slideIndex) {
        this.currentIndex = Number(this.slideIndex);
      } else {
        this.currentIndex = 0;
      }

      this.forms = Array(this.slides.length).fill(null);
    }
  }

  private updateSlideHiddenButtons(status: number) {
    switch (status) {
      case CO_STATUS_CONST.ACEITO:
        this.noButtonSlideType = [
          'cover',
          'offer-detail',
          'question',
          'rejected-offer',
        ];
        break;
      case CO_STATUS_CONST.RECUSADO:
        this.noButtonSlideType = [
          'cover',
          'offer-detail',
          'question',
          'accepted-offer',
        ];
        break;

      default:
        this.noButtonSlideType = [
          'cover',
          'offer-detail',
          'accepted-offer',
          'rejected-offer',
        ];
    }
  }

  private updateSlide(form: FormGroup, index: number) {
    const values = form.value;
    for (const key of Object.keys(values)) {
      this.slides[index].initialValue[key] = values[key];
    }
  }
}
