import { Injectable } from '@angular/core';
import { AngularFireDatabase } from '@angular/fire/compat/database';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import {
  BehaviorSubject,
  Subject,
  firstValueFrom,
  lastValueFrom,
  takeUntil,
} from 'rxjs';
import {
  PropostaSalarialModel,
  PropostaSalarialView,
} from '../models/proposta-salarial.model';
import { RejectedReason } from '../models/rejected-reason-alert.models';
import { CO_STATUS_CONST } from '../utils/utils';
import { GestorService } from './gestor.service';
import * as dayjs from 'dayjs';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class PropostaSalarialService {
  private basePath: string;
  private dbPath: string;
  private propostaView: PropostaSalarialView;
  private status$: BehaviorSubject<number> = new BehaviorSubject(null);
  private templateId: string;
  onDestroy$ = new Subject();

  constructor(
    private firestore: AngularFirestore,
    private db: AngularFireDatabase,
    private authService: AuthService,
    private gestorService: GestorService
  ) {
    this.basePath = `empresaMae/${this.authService.dominioId}`;
    this.dbPath = `${this.authService.dominioId}/carta-oferta/home-list`;
    this.propostaView = {};
  }

  getView() {
    return this.propostaView;
  }

  getStatus$() {
    return this.status$;
  }

  async getDados() {
    const prop = await lastValueFrom(this.getProposal());
    if (!prop.exists) {
      return {};
    }
    const proposta = prop.data() as PropostaSalarialModel;
    this.templateId = proposta.templateCartaOferta.split('/').pop();
    this.setPropostaView(proposta, prop.ref.id);

    return proposta;
  }

  async setPropostaView(proposta: PropostaSalarialModel, id: string) {
    this.propostaView = {
      ...proposta,
      id,
      cargo: await this.getCargo(proposta.cargo),
      modeloTrabalho: this.getModeloTrabalho(
        Number.parseInt(proposta.modeloTrabalho)
      ),
    };

    this.status$.next(proposta.status);
    this.loadGestor(proposta.gestor);
  }
  getProposal() {
    if (this.authService.isPreview) {
      return this.getProposalDraft();
    }
    return this.getCreatedProposal();
  }
  getCreatedProposal() {
    return this.firestore
      .doc(`${this.basePath}/propostasSalariais/${this.authService.userId}`)
      .get();
  }

  getProposalDraft() {
    return this.firestore
      .doc(`${this.basePath}/proposalsDraft/${this.authService.userId}`)
      .get();
  }

  async getCargo(id: string) {
    return firstValueFrom(
      this.firestore.doc(`${this.basePath}/cargos/${id}`).get()
    ).then((doc: any) => {
      return doc.exists ? doc.data().nome : null;
    });
  }

  async aceitarOferta() {
    return this.changeStatus(CO_STATUS_CONST.ACEITO);
  }

  async recusarOferta(reason: RejectedReason) {
    return this.changeStatus(CO_STATUS_CONST.RECUSADO, reason);
  }

  loadTemplate() {
    const path = `empresaMae/${this.authService.dominioId}/olTemplate/${this.templateId}`;
    return this.firestore
      .doc(path)
      .valueChanges()
      .pipe(takeUntil(this.onDestroy$));
  }

  async visualizarOferta() {
    await this.changeStatus(CO_STATUS_CONST.VISUALIZADO);
  }

  private async changeStatus(coStatus: number, reason?: RejectedReason) {
    const propPath = `${this.basePath}/propostasSalariais/${this.authService.userId}`;
    const removalDate =
      coStatus === (6 || 7) ? dayjs().add(7, 'day').valueOf() : null;
    await this.firestore.collection(`${propPath}/historico`).add({
      updatedBy: this.authService.userId,
      updatedAt: new Date(),
      status: coStatus,
      reason: reason ? reason : null,
      oldStatus: this.propostaView.status,
    });

    await this.firestore.doc(propPath).update({ status: coStatus });
    await this.firestore
      .doc(propPath)
      .update({ reason: reason ? reason : null });
    await this.addRegistroAtividade(
      this.authService.userId,
      coStatus,
      reason ? reason : null
    );
    this.db.database.ref(`${this.dbPath}/${this.authService.userId}`).update({
      status: coStatus,
      removalDate,
      reason: reason ? reason : null,
    });

    this.status$.next(coStatus);

    // todo: alterar realtime também
    this.propostaView.status = coStatus;
  }

  private getModeloTrabalho(modeloTrabalho: number) {
    switch (modeloTrabalho) {
      case 1:
        return 'Presencial';
      case 2:
        return 'Remoto';
      case 3:
        return 'Híbrido';
    }

    return '';
  }

  private loadGestor(gestorPath: string) {
    if (gestorPath) {
      this.gestorService
        .getGestorName(gestorPath)
        .subscribe((gestorName) => (this.propostaView.gestorName = gestorName));
    }
  }

  private async addRegistroAtividade(
    propostaId: string,
    reply: number,
    reason?: RejectedReason
  ) {
    const atualRegistroAtiv = (
      await this.getRegistroAtivValue(propostaId)
    ).val();
    atualRegistroAtiv.push({
      atividade:
        reply === 6
          ? 'PROPOSTA_ACEITA'
          : reply === 7
          ? 'PROPOSTA_RECUSADA'
          : 'PROPOSTA_VISUALIZADA',
      feitoPor: 'candidate',
      modulo: 'CARTA_OFERTA',
      timestamp: dayjs().valueOf(),
      reason: reason ? reason : null,
    });
    return this.db.database
      .ref(`${this.dbPath}/${propostaId}`)
      .child('registroAtiv')
      .set(atualRegistroAtiv);
  }

  async getRegistroAtivValue(propostaId: string) {
    return this.db.database
      .ref(`${this.dbPath}/${propostaId}`)
      .child('registroAtiv')
      .once('value');
  }
}
