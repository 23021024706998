<div class="hr-slide-area">
  <div class="ol-template-back-bar"></div>
  <div class="ol-template-slide-data">
    <div class="slide-data-area">
      <img
        class="logo-image"
        [src]="logoUrl"
        alt="logo da empresa"
      />

      <div class="ol-template-slide-info">
        <div
          class="ol-template-slide-title"
          [innerHTML]="initialValue.title"
        ></div>
        <div
          class="ol-template-slide-description"
          [innerHTML]="initialValue.description"
        ></div>

        <form [formGroup]="formGroup" class="ol-template-slide-form" novalidate>
          <div
            class="hr-form-field"
            [ngClass]="{
              'has-error':
                formGroup.get('digitos').touched &&
                formGroup.get('digitos').invalid
            }"
          >
            <input
              class="hr-input-co"
              formControlName="digitos"
              type="text"
              id="hrNewPass"
              placeholder="----"
              maxlength="4"
            />

            <div>
              <span class="hr-hint-error">{{
                getFormErrorMessage(
                  "digitos",
                  "Últimos quatro dígitos do celular"
                )
              }}</span>
            </div>
          </div>
          <div>
            <button
              class="hr-btn hr-btn-primary poster-btn"
              (click)="verificar()"
            >
              {{ initialValue.buttonLabel }}
            </button>
          </div>

          <!-- <button
              class="hr-btn"
              id="hrEntrarBtn"
              (click)="verificar()"
              [disabled]="this.formGroup.get('digitos').value.length !== 4"
            >
              Validar
            </button> -->
        </form>
      </div>
    </div>
  </div>
</div>
