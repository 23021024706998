import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'paddingNumber',
})
export class PaddingNumberPipe implements PipeTransform {
  transform(n: number, length = 2): string {
    let nString = `${n}`;
    while (nString.length < length) {
      nString = `0${nString}`;
    }
    return nString;
  }
}
