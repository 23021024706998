import { Injectable } from '@angular/core';
import { ActivatedRouteSnapshot, Resolve, Router } from '@angular/router';
import { Observable } from 'rxjs';
import { PropostaSalarialService } from 'src/app/services/proposta-salarial.service';

@Injectable({
  providedIn: 'root',
})
export class TemplateResolver implements Resolve<any> {
  constructor(private propostaService: PropostaSalarialService) {}

  resolve(route: ActivatedRouteSnapshot): Observable<any> | Promise<any> | any {
    let slideIndex = route.paramMap.get('slideIndex');
    if (!slideIndex) {
      slideIndex = '0';
    }

    return this.propostaService.getDados().then((prop) => {
      return { slideIndex, ...prop };
    });
  }
} 
