import { FormArray } from '@angular/forms';

export const formGroupIterator = (formGroup, cb, onFinish = null) => {
  if (!formGroup || !formGroup.controls) {
    return null;
  }

  const formGroupObj = Object.keys(formGroup.controls);

  for (let i = 0; i < formGroupObj.length; i++) {
    const obj = formGroup.get(formGroupObj[i]);
    const type = obj.constructor.name;

    if (_checkFormType(obj) === 'FormControl') {
      cb(obj);
    } else if (_checkFormType(obj) === 'FormGroup') {
      formGroupIterator(obj, cb);
    } else if (_checkFormType(obj) === 'FormArray') {
      const formArray = <FormArray>obj;
      const formArrayControls = formArray.controls;

      for (let j = 0; j < formArrayControls.length; j++) {
        formGroupIterator(formArrayControls[j], cb);
      }
    }

    if (onFinish && i === formGroupObj.length - 1) {
      onFinish();
    }
  }
};

const _checkFormType = function (obj) {
  if (typeof obj.registerControl === 'function') {
    return 'FormGroup';
  } else if (typeof obj.length === 'number') {
    return 'FormArray';
  } else {
    return 'FormControl';
  }
};
