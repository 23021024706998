import { Injectable } from '@angular/core';
import { AuthService } from './auth.service';
import { ActivatedRouteSnapshot, CanActivate, Router } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import * as dayjs from 'dayjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { LoaderService } from './loader.service';

@Injectable({
  providedIn: 'root',
})
export class SignInPreviewService implements CanActivate {
  constructor(
    private firestore: AngularFirestore,
    private router: Router,
    private afAuth: AngularFireAuth,
    private authService: AuthService,
    private loaderService: LoaderService
  ) {}

  canActivate(route: ActivatedRouteSnapshot) {
    this.loaderService.showLoader();
    return this.getTemporaryToken(route);
  }

  protected isValidMode(mode: string | null) {
    return mode === 'preview';
  }

  async visualizerMode(route: ActivatedRouteSnapshot) {
    const matrizId = route.queryParamMap.get('company');

    this.authService.matriz = `empresaMae/${this.authService.dominioId}/empresas/${matrizId}`;
    await this.authService.setCompanyProperties();
    this.loaderService.hideLoader();
    this.router.navigate([`preview/${this.authService.userId}`]);
    return true;
  }

  private async getTemporaryToken(route: ActivatedRouteSnapshot) {
    const userId = route.queryParamMap.get('userId');
    const domainId = route.queryParamMap.get('domainId');
    const token = route.queryParamMap.get('token');

    if (!this.validateParams(userId, domainId)) return false;

    const customToken = await this.getCustomToken(domainId, userId);

    if (
      !customToken ||
      !this.validateCustomTokenExpiration(customToken.expirationDate)
    )
      return false;

    if (this.shouldRedirectInvalidRoute(route)) return false;

    try {
      const newToken = this.replaceObscuringString(
        token,
        customToken.obscuringString
      );
      const user = await this.authenticateUser(newToken);

      if (!user) return false;

      this.updateAuthServiceProperties(user, domainId);
      return this.visualizerMode(route);
    } catch (error) {
      await this.authService.redirect(`--error: ${error}`);
      this.loaderService.hideLoader()
      return false;
    }
  }

  private validateParams(userId: string, domainId: string): boolean {
    if (!userId || !domainId) {
      this.authService.redirect('--not able to open--');
      return false;
    }
    return true;
  }

  private async getCustomToken(domainId: string, userId: string) {
    const customTokenDoc = await lastValueFrom(
      this.firestore.doc(`empresaMae/${domainId}/customToken/${userId}`).get()
    );

    return customTokenDoc.exists
      ? (customTokenDoc.data() as { obscuringString: string; expirationDate })
      : null;
  }

  private validateCustomTokenExpiration(expirationDate): boolean {
    if (dayjs(expirationDate.toDate()).isBefore()) {
      this.authService.redirect('--token expired--');
      return false;
    }
    return true;
  }

  private shouldRedirectInvalidRoute(route: ActivatedRouteSnapshot): boolean {
    const path = route.routeConfig.path;
    if (['login', 'email'].includes(path) && this.authService.isPreview) {
      this.authService.redirect('invalid route');
      return true;
    }
    return false;
  }

  private replaceObscuringString(
    token: string,
    obscuringString: string
  ): string {
    return token.replace(obscuringString, '');
  }

  private async authenticateUser(token: string) {
    const tokenValidationResult: any = await lastValueFrom(
      this.authService.validateToken(token)
    );
    if (!tokenValidationResult.token) {
      this.authService.redirect('--token not valid');
      return false;
    }
    return this.afAuth.signInWithCustomToken(tokenValidationResult.token);
  }

  private updateAuthServiceProperties(user: any, domainId: string) {
    this.authService.userId = user.user.uid;
    this.authService.isPreview = true;
    this.authService.dominioId = domainId;
    this.authService.user.next(user);
  }
}
