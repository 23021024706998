import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material/dialog';
import { RejectedReasonAlertComponent } from './rejected-reason-alert/rejected-reason-alert.component';

@Component({
  selector: 'hr-rejected-alert',
  templateUrl: './rejected-alert.component.html',
  styleUrls: ['./rejected-alert.component.scss'],
})
export class RejectedAlertComponent implements OnInit {
  constructor(
    private dialogRef: MatDialogRef<RejectedAlertComponent>,
    private dialog: MatDialog
  ) {}

  ngOnInit() {}

  closeModal() {
    this.dialogRef.close();
  }

  rejectOffer() {
    this.dialog
      .open(RejectedReasonAlertComponent, {
        panelClass: [
          'hr-modal',
          'hr-thin-modal',
          'hr-modal-rejected-reason',
        ],
        backdropClass: 'hr-modal-backdrop-close',
      })
      .afterClosed()
      .subscribe(async (reason) => {
        if (reason) {
          this.dialogRef.close(reason);
        }
      });
  }
}
