import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SlideBaseComponent } from 'src/app/shared/slider/slide/slide-base.component';
import { ITemplateSlide } from 'src/app/models/templates.model';
import { BeneficioService } from 'src/app/services/pacote-beneficio.service';
import { grupoBenesView } from 'src/app/models/pacote-beneficio.models';
import { BeneficioDetalheComponent } from 'src/app/components/beneficio-detalhe/beneficio-detalhe.component';
import { MatDialog } from '@angular/material/dialog';

@Component({
  selector: 'hr-dynamic-benefits',
  templateUrl: './dynamic-benefits.component.html',
  styleUrls: ['./dynamic-benefits.component.scss'],
  providers: [BeneficioService],
})
export class DynamicBenefitsComponent extends SlideBaseComponent {
  override initialValue: ITemplateSlide;
  gruposBene: grupoBenesView[];

  constructor(
    private formBuilder: FormBuilder,
    beneService: BeneficioService,
    private dialog: MatDialog
  ) {
    super();

    beneService.getGrupos().then((grupos) => {
      this.gruposBene = grupos;
    });
  }

  protected onInit(): void {}
  protected onDestroy(): void {}

  protected initForm(): void {
    this.form = this.formBuilder.group({
      slideName: [null, [Validators.required, Validators.maxLength(20)]],
      title: [null, [Validators.required, Validators.maxLength(40)]],
      description: [null, [Validators.required, Validators.maxLength(1500)]],
    });
  }

  openBeneDetails(bene) {
    this.dialog.open(BeneficioDetalheComponent, {
      data: {
        description: bene.description,
        image: bene.image,
        name: bene.name,
        video: bene.videoObject,
        videoPath: bene.videoPath
      },
      panelClass: ['hr-modal', 'hr-thin-modal', 'hr-benefit-modal'],
      backdropClass: 'hr-modal-backdrop-close',
    });
  }
}
