<div
  class="hr-slide-area"
  *hrVar="initialValue.backgroundImageUrl as bgImage"
  [ngStyle]="{ 'background-image': 'url(' + bgImage + ')' }"
>
  <div class="slide-data-area">
    <img
      class="logo-image"
      [src]="initialValue.logoImageUrl"
      alt="logo da empresa"
    />
    <div class="poster-title">{{ initialValue.title }}</div>
    <div class="poster-description">{{ initialValue.description }}</div>

    <button class="hr-btn hr-btn-primary poster-btn" (click)="goToNextSlide()">
      {{ initialValue.buttonLabel }}
    </button>
  </div>
</div>
