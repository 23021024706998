<div class="rejected-offer-container">
  <div class="hr-modal-close-button hr-tooltip-trigger" (click)="closeModal()">
    <svg-icon
      class="hr-svg-icon"
      src="../../../assets/icons/components/close-x.svg"
    ></svg-icon>

    <div class="hr-tooltip-content hr-mini-tooltip">
      <span>Fechar</span>
    </div>
  </div>

  <div class="modal-content">
    <span class="modal-title"
      >Tem certeza? Adorariamos ter você na nossa equipe.</span
    >
    <span class="modal-description"
      >Pense bem, pois a vaga será passada para um próximo candidato em caso de
      rejeição.</span
    >
    <div class="modal-btns">
      <button class="hr-button-primary" (click)="rejectOffer()">
        <span>Sim, recusar oferta</span>
      </button>
      <button class="hr-button-primary-outline" (click)="closeModal()">
        <span>Cancelar</span>
      </button>
    </div>
  </div>
</div>
