import { Injectable } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { ActivatedRouteSnapshot, CanActivate } from '@angular/router';
import { lastValueFrom } from 'rxjs';
import { AngularFireAuth } from '@angular/fire/compat/auth';
import { AuthService } from './auth.service';

@Injectable({
  providedIn: 'root',
})
export class SignupService implements CanActivate {
  constructor(
    private afAuth: AngularFireAuth,
    private authService: AuthService,
    private analytics: AngularFireAnalytics
  ) {}

  async canActivate(route: ActivatedRouteSnapshot): Promise<boolean> {
    const token = route.queryParamMap.get('token');
    if (!token) {
      this.authService.redirect('--token not received');
      return false;
    }

    try {
      const tokenValidationResult: any = await lastValueFrom(
        this.authService.validateToken(token)
      );

      if (!tokenValidationResult.token) {
        this.authService.redirect('--token not valid');
        return false;
      }
      const user = await this.afAuth.signInWithCustomToken(
        tokenValidationResult.token
      );

      if (!user) {
        this.authService.redirect('--user not authenticated');
        return false;
      }

      this.authService.userId = user.user.uid;
      this.analytics.setUserId(this.authService.userId);

      const tokenResult = await user.user.getIdTokenResult();
      this.setUserProperties(tokenResult.claims);

      return this.phoneVerification();
    } catch (error) {
      await this.authService.redirect(`--error: ${error}`);
      return false;
    }
  }

  private setUserProperties(claims) {
    this.authService.dominioId = claims['dominioID'] as string;
    this.authService.phoneNumber = claims['phoneNumber'] as string;
    this.authService.matriz = claims['matriz'] as string;
    this.analytics.setUserProperties({ dominioId: this.authService.dominioId });
  }

  protected logEvent(msg: string) {
    this.analytics.logEvent(msg, {
      dominioId: this.authService.dominioId,
      userId: this.authService.userId,
    });
  }

  private async phoneVerification() {
    if (this.authService.phoneNumber) {
      await this.authService.setCompanyProperties();
      console.log('--phoneNumber ok');
      this.logEvent('hr_ol_phone_verification');
      return true;
    } else {
      this.logEvent('hr_ol_phone_number_404');
      console.log('--phoneNumber not found');
      return false;
    }
  }
}
