import { NgModule } from '@angular/core';
import { ClickOutsideButtonDirective } from './click-outside-button.directive';
import { ClickOutsideDirective } from './click-outside.directive';

@NgModule({
  imports: [],
  declarations: [ClickOutsideButtonDirective, ClickOutsideDirective],
  exports: [ClickOutsideButtonDirective, ClickOutsideDirective],
})
export class ClickOutsideModule {}
