import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { environment } from 'src/environments/environment';
import { LoaderService } from './services/loader.service';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss'],
})
export class AppComponent {
  title = 'hr-offer-letter';

  constructor(translate: TranslateService, public loaderService: LoaderService) {
    console.log('v1.0.15', environment.envName);

    translate.setDefaultLang('pt');
    translate.use('pt');
  }
}
