import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SlideBaseComponent } from 'src/app/shared/slider/slide/slide-base.component';
import { ITemplateCover } from 'src/app/models/templates.model';
import { Router } from '@angular/router';
import { PropostaSalarialService } from 'src/app/services/proposta-salarial.service';

@Component({
  selector: 'hr-dynamic-cover',
  templateUrl: './dynamic-cover.component.html',
  styleUrls: ['./dynamic-cover.component.scss'],
  providers: [FormBuilder],
})
export class DynamicCoverComponent extends SlideBaseComponent {
  override initialValue: ITemplateCover;

  constructor(
    private formBuilder: FormBuilder,
    private router: Router,
    private proposta: PropostaSalarialService
  ) {
    super();
  }

  protected onInit(): void {}
  protected onDestroy(): void {}

  protected initForm(): void {
    this.form = this.formBuilder.group({
      slideName: [null, [Validators.required, Validators.maxLength(20)]],
      title: [null, [Validators.required, Validators.maxLength(40)]],
      description: [null, [Validators.required, Validators.maxLength(200)]],
      buttonLabel: [null, [Validators.required, Validators.maxLength(40)]],
      backgroundImage: [null, [Validators.required]],
      backgroundImageUrl: [null, [Validators.required]],
      logoImage: [null, [Validators.required]],
      logoImageUrl: [null, [Validators.required]],
    });
  }


}
