export const CO_STATUS_CONST = {
  ENVIADO: 1,
  VISUALIZADO: 2,
  EM_ANALISE: 3,
  // PENDENTE_APROVACAO: 3,
  REPROVADO: 4, // Gestor não aprova
  EM_NEGOCIACAO: 5,
  ACEITO: 6,
  RECUSADO: 7,
  EXPIRADO: 8,
  CANCELADO: 9,
};
