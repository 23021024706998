import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map, of } from 'rxjs';

@Injectable({
  providedIn: 'root',
})
export class GestorService {
  constructor(private firestore: AngularFirestore) {}

  getGestorName(gestorPath: string) {
    if (gestorPath) {
      return this.firestore
        .doc(gestorPath)
        .get()
        .pipe(map((snap) => (snap.exists ? snap.data()['nomeCompleto'] : '')));
    }
    return of('');
  }
}
