export const environment = {
  production: true,
  envName: 'prod',
  urlApp: 'https://hr-offer-letter-prod.firebaseapp.com',
  urlServerless: 'https://us-central1-hrestart-prod.cloudfunctions.net/',
  firebase: {
    apiKey: 'AIzaSyAIlBY45yTBq77MRtknC52QDKTaBMvVEAM',
    authDomain: 'hrestart-prod.firebaseapp.com',
    databaseURL: 'https://hrestart-prod.firebaseio.com',
    projectId: 'hrestart-prod',
    storageBucket: 'hrestart-prod.appspot.com',
    messagingSenderId: '1080423684047',
    appId: "1:1080423684047:web:ae6cece55f6c844ad8e4ba",
    measurementId: "G-XSQNJTTKXV"
  },
};
