import { Injectable } from '@angular/core';

const FormErrorMessages = {
  email: 'Email não é válido',
  cpf: 'CPF não é válido',
  password:
    'A senha deve conter no mínimo 8 caracteres sendo pelo menos um número e uma letra',
  confirmPass: 'A senha digitada nos dois campos deve ser igual',
  minlength: (error) =>
    `Campo deve ter ao menos ${error.requiredLength} caracteres`,
  maxlength: (error) =>
    `Campo não deve ter mais que ${error.requiredLength} caracteres`,
  idade: (error) => `Necessário para maiores de ${error.value} anos`,
  matDatepickerMax: (error) => `Data inválida`,
  matDatepickerParse: (error) => `Data inválida`,
  emailOrCpfIncorrect: (error) => `Email ou CPF inválidos`,
  required: (error, label) => `${label} é obrigatório`,
  pattern: (error) => {
    const FormErrorPatternMessages = {
      '^[a-zA-Z\\s]+$': 'Não é permitido números e caracteres especiais',
      '^[0-9]+$': 'Só é permitido caracteres numéricos',
    };

    return FormErrorPatternMessages[error.requiredPattern];
  },
};

const ErrorsMessages = {
  EMPTY: 'E-mail/Senha não informado',
  EMPTY_EMAIL_CPF: 'E-mail/CPF não informado',
  INCORRECT: 'E-mail/Senha incorreto',
  INCORRECT_EMAIL_CPF: 'E-mail/CPF incorretos',
  'ROLE-INVALID': 'Usuário sem acesso',
  'auth/wrong-password': 'E-mail/Senha incorreto',
  'auth/too-many-requests': 'E-mail/Senha incorreto',
  'auth/invalid-email': 'E-mail/Senha incorreto',
  'auth/user-not-found': 'O e-mail informado não está cadastrado',
  'auth/network-request-failed': 'Falha na autenticação',
  'auth/user-disabled': 'Usuário sem acesso',
  'cpf-not-found': 'O CPF informado não está cadastrado',
  MISSING_PERMISSION: 'Usuário sem acesso',
  'phone-not-found': 'Quatro últimos digitos incorretos',
  'offer-letter-not-found': 'Nenhuma proposta salarial enviada disponível',
};

@Injectable()
export class ErrorService {
  constructor() {}

  getFormMessage(errors: object, label: string = null) {
    for (const key in errors) {
      if (errors.hasOwnProperty(key)) {
        if (typeof FormErrorMessages[key] === 'function') {
          return FormErrorMessages[key](errors[key], label);
        } else {
          return FormErrorMessages[key];
        }
      }
    }
  }

  getErrorMessage(error: string) {
    return ErrorsMessages[error];
  }
}
