<div class="hr-slide-area">
  <div class="ol-template-back-bar"></div>
  <div class="ol-template-slide-data">
    <hr-video-player [videoObject]="initialValue.videoObject" [videoUrl]="initialValue.videoUrl"></hr-video-player>

    <div class="ol-template-slide-info">
      <div
        class="ol-template-slide-title"
        [innerHTML]="
          initialValue.title.replace('#{nome_profissional}', nomeProfissional)
        "
      ></div>
      <div
        class="ol-template-slide-description"
        [innerHTML]="initialValue.description.replace('#{nome_profissional}', nomeProfissional)"
      ></div>
      <div class="welcome-quotes" *ngIf="initialValue.quotes">
        <div class="icon-area">
          <mat-icon>format_quote</mat-icon>
        </div>
        <span [innerHTML]="initialValue.quotes"></span>
      </div>
    </div>
  </div>
</div>
