<div class="hr-slide-area">
  <div class="ol-template-back-bar"></div>
  <div class="ol-template-slide-data">
    <div class="slide-data-area">
      <div class="ol-template-slide-info">
        <div
          class="ol-template-slide-title"
          [innerHTML]="initialValue.title"
        ></div>
        <div
          class="ol-template-slide-description"
          [innerHTML]="initialValue.description"
        ></div>
      </div>
      <div class="benefit-types">
        <div
          class="benefit-type-area"
          *ngFor="let grupo of gruposBene; let i = index"
        >
          <div class="benefit-type">
            <div class="type-icon">
              <svg-icon
                class="hr-svg-icon"
                [src]="grupo.icon"
                *ngIf="!grupo.icon?.startsWith('https://'); else imageTemplate"
              ></svg-icon>
              <ng-template #imageTemplate>
                <img [src]="grupo.icon"
                  alt="icone de {{ grupo.title }}"
                />
              </ng-template>
            </div>
            <div class="title">{{ grupo.title }}</div>
          </div>
          <div class="benefits">
            <div
              class="benefit-area"
              *ngFor="let bene of grupo.benes; let j = index"
              (click)="openBeneDetails(bene)"
            >
              <div class="title">
                {{ bene.name }}
              </div>
              <div class="open-icon">
                <svg-icon
                  class="hr-svg-icon"
                  src="/assets/icons/shared/arrow-full.svg"
                ></svg-icon>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>
