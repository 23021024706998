import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { SlideBaseComponent } from 'src/app/shared/slider/slide/slide-base.component';
import { ITemplateOfferDetail } from 'src/app/models/templates.model';
import { PropostaSalarialService } from 'src/app/services/proposta-salarial.service';
import { GestorService } from 'src/app/services/gestor.service';

@Component({
  selector: 'hr-dynamic-offer-detail',
  templateUrl: './dynamic-offer-detail.component.html',
  styleUrls: ['./dynamic-offer-detail.component.scss'],
  providers: [FormBuilder],
})
export class DynamicOfferDetailComponent extends SlideBaseComponent {
  override initialValue: ITemplateOfferDetail;
  salario: number;
  dataAdmissao: string;
  gestor: string;
  hiringBonus: string;
  iLP: string;
  modeloTrabalho: string;
  localTrabalho: string;
  cargaHoraria: string;

  constructor(
    private formBuilder: FormBuilder,
    private propostaService: PropostaSalarialService
  ) {
    super();
    const proposta = this.propostaService.getView();
    this.salario = proposta.salario;
    this.dataAdmissao = proposta.dataAdmissao.toDate().toLocaleDateString();
    this.gestor = proposta.gestorName;
    this.modeloTrabalho = proposta.modeloTrabalho;
    this.localTrabalho = proposta.localTrabalho;
    this.cargaHoraria = proposta.cargaHoraria;
    this.hiringBonus = proposta.hiringBonus ? proposta.hiringBonus : null;
    this.iLP = proposta.iLP ? proposta.iLP : null;
  }

  protected onInit(): void {}
  protected onDestroy(): void {}

  protected initForm(): void {
    this.form = this.formBuilder.group({
      slideName: [null, [Validators.required, Validators.maxLength(20)]],
      title: [null, [Validators.required, Validators.maxLength(40)]],
      wageSubtitle: [null, [Validators.required, Validators.maxLength(40)]],
      variableWage: [null, [Validators.required, Validators.maxLength(20)]],
      variableWageSubtitle: [
        null,
        [Validators.required, Validators.maxLength(40)],
      ],
      secondaryCall: [null, [Validators.required, Validators.maxLength(40)]],
      firstImage: [null],
      firstImageUrl: [null],
      description: [null, [Validators.maxLength(1500)]],
      note: [null, [Validators.required, Validators.maxLength(400)]],
    });
  }

  getCurrency(value: string | number) {
    let valueSplit = value.toString().split(/[,.]+/);
    let cents = valueSplit[valueSplit.length - 1];
    if (valueSplit.length === 1 || cents.length > 2) {
      cents = '00';
      return this.setMilhar(valueSplit.join(), cents);
    } else {
      cents = cents.length === 2 ? cents : `${cents}0`;
      valueSplit.pop();
      return this.setMilhar(valueSplit.join(''), cents);
    }
  }

  setMilhar(int: string, cents: string) {
    if (int.length > 3) {
      let newValue = int.slice(0, -3) + '.' + int.slice(-3);
      return newValue + ',' + cents;
    }
    return int + ',' + cents;
  }
}
