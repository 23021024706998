import { Component, OnInit } from '@angular/core';
import { AngularFireAnalytics } from '@angular/fire/compat/analytics';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { Router } from '@angular/router';
import { AlertComponent } from 'src/app/components/alert/alert.component';
import { PropostaSalarialView } from 'src/app/models/proposta-salarial.model';
import { ErrorService } from 'src/app/services/error.service';
import { PropostaSalarialService } from 'src/app/services/proposta-salarial.service';
import { CO_STATUS_CONST } from 'src/app/utils/utils';
import { AuthService } from 'src/app/services/auth.service';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss'],
})
export class LoginComponent implements OnInit {
  public formGroup: FormGroup;
  initialValue;
  logoUrl: string;
  constructor(
    public authService: AuthService,
    private fb: FormBuilder,
    private dialog: MatDialog,
    private errorService: ErrorService,
    private router: Router,
    private propostaService: PropostaSalarialService,
    private analytics: AngularFireAnalytics
  ) {
    this.initialValue = {
      title:
        'Que tal conferir nossa proposta? Mas antes, vamos validar seu acesso!',
      description:
        'Nos informe os últimos quatro dígitos do seu número de celular para verificação do recebimento da sua carta oferta.',
      buttonLabel: 'Validar',
    };

    this.logoUrl = authService.logoUrl;
  }

  ngOnInit(): void {
    this.formGroup = this.fb.group({
      digitos: [
        '',
        [Validators.required, Validators.min(4), Validators.max(4)],
      ],
    });
  }

  getFormErrorMessage(formControlName: string, label) {
    const errors = this.formGroup.controls[formControlName].errors;
    return this.errorService.getFormMessage(errors, label);
  }

  async verificar() {
    const lastFourDigits = this.formGroup.get('digitos').value;

    if (this.authService.phoneNumber) {
      if (
        this.authService.phoneNumber.slice(
          this.authService.phoneNumber.length - 4
        ) !== lastFourDigits
      ) {
        this.warmDialog(null);
        return;
      }
    }

    this.propostaService.getDados().then((prop: PropostaSalarialView) => {
      if (
        prop.status &&
        (prop.status === CO_STATUS_CONST.ENVIADO ||
          prop.status === CO_STATUS_CONST.VISUALIZADO ||
          prop.status === CO_STATUS_CONST.ACEITO)
      ) {
        if (prop.status === CO_STATUS_CONST.ENVIADO) {
          this.propostaService.visualizarOferta();
        }

        this.analytics.logEvent('hr_ol_visualized', {
          dominioId: this.authService.dominioId,
          userId: this.authService.userId,
        });

        this.router.navigate([`proposta/${this.authService.userId}`]);
      } else {
        this.warmDialog('offer-letter-not-found');
        return;
      }
    });

    // const prop = await this.propostaService.VerifyAcess(lastFourDigits);

    // if (prop.length !== 0) {
    //   const id = prop[0].id;
    //   this.router.navigate([`proposta/${id}`]);
    // } else {
    //   this.warmDialog(null);
    // }
  }

  warmDialog(error: string) {
    if (!error) {
      error = 'phone-not-found';
    }
    const message = this.errorService.getErrorMessage(error);

    this.dialog.open(AlertComponent, {
      data: {
        message,
        buttons: [
          {
            label: 'Ok',
            class: 'hr-btn-primary',
            value: true,
          },
        ],
      },
      panelClass: 'alertModal',
    });
  }
}
