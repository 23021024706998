<div class="hr-slide-area">
  <div class="ol-template-back-bar"></div>
  <div class="ol-template-slide-data">
    <div class="slide-data-area">
      <div class="ol-template-slide-info">
        <div
          class="ol-template-slide-title"
          [innerHTML]="initialValue.title"
        ></div>
      </div>
      <div class="groups-area" *ngIf="initialValue.groups?.length">
        <hr-list-slider [noGradient]="true">
          <div
            class="image-area"
            *ngFor="let group of initialValue.groups; let i = index"
            [ngClass]="{ selected: selectedGroup === i }"
            (click)="selectGroup(i)"
          >
            <img
              class="first-image"
              [src]="group.groupImageUrl"
              [alt]="group.name + ' logo'"
            />
          </div>
        </hr-list-slider>
        <div
          class="ol-template-slide-description"
          [innerHTML]="initialValue.groups[selectedGroup].description"
        ></div>
      </div>
    </div>
  </div>
</div>
