import { Directive, ElementRef, EventEmitter, HostListener, Input, Output } from '@angular/core';

@Directive({
  selector: '[hrClickOutsideButton]',
})
export class ClickOutsideButtonDirective {
  private isOpen = false;

  @Output() public hrClickOutsideButton = new EventEmitter();
  @Input() public validatorMenu;
  @Input() public closeByButton;
  constructor(private _elementRef: ElementRef) {}
  @HostListener('document:click', ['$event.target'])
  public onClick(target) {
    if (this.validatorMenu && !this.isOpen) {
      this.isOpen = true;
      return;
    }
    if (!this.validatorMenu && this.closeByButton) {
      this.isOpen = false;
      return;
    }
    if (this.validatorMenu) {
      const evento = this._elementRef.nativeElement.contains(target);
      if (!evento) {
        this.hrClickOutsideButton.emit(null);
        this.isOpen = false;
      }
    }
  }
}
