import { Component, ElementRef, Input, ViewChild } from '@angular/core';
import { IVideoObject, IVideoView } from 'src/app/models/video.model';
import { VideoService } from 'src/app/services/video.service';
declare const jwplayer: any;

@Component({
  selector: 'hr-video-player',
  templateUrl: './video-player.component.html',
  styleUrls: ['./video-player.component.scss'],
  providers: [VideoService],
})
export class VideoPlayerComponent {
  videoKey: string;
  video: IVideoView;
  loading: boolean;
  play: boolean;

  url: string;
  @ViewChild('videoPlayer') videoPlayer: ElementRef; // Obtendo uma referência ao elemento de vídeo no template

  @Input() set videoObject(videoObject: IVideoObject) {
    if (videoObject) {
      this.videoKey = videoObject.mediaKey;
      this.getVideo(videoObject);
    }
  }

  @Input() set videoUrl(url: string) {
    if (url) {
      this.url = url;
    }
  }
  constructor(private videoService: VideoService) {}

  private getVideo(videoObj: IVideoObject) {
    this.videoService.getVideo(videoObj).subscribe((video) => {
      if (video && video.manifest) {
        this.video = video;
        this.setupPlayer();
        this.loading = false;
      }
    });
  }

  private setupPlayer(): any {
    console.log('video-player-' + this.videoKey);
    this.videoPlayer.nativeElement.id = `video-player-${this.videoKey}`;
    const playerInstance = jwplayer(`video-player-${this.videoKey}`);
    console.log('playerINstance', playerInstance);
    playerInstance.setup({
      playlist: [
        {
          file: `https://cdn.jwplayer.com/${this.video.manifest}`,
          image: this.video.preview,
        },
      ],
      skin: {
        name: 'hrestart',
      },
      intl: {
        en: {
          replay: 'Repetir',
          play: 'Reproduzir',
          pause: 'Pausar',
          rewind: 'Voltar 10s',
          settings: 'Configurações',
          fullscreen: 'Tela cheia',
          exitFullscreen: 'Sair da tela cheia',
          hd: 'Qualidade',
        },
      },
    });

    // if (this.play) {
    //   playerInstance.play();
    // }

    // playerInstance.on('play', (e) => {
    //   console.log('video started');
    //   this.videoService.setVideoStarted(
    //     this.authService.dominioId,
    //     this.authService.userId,
    //     this.video.videoRef.ref.id
    //   );
    //   this.videoService.setUserVisualization(
    //     new Date(),
    //     this.authService.userId,
    //     this.video.videoRef
    //   );
    //   playerInstance.off('play');
    // });
    // playerInstance.on('complete', (e) => {
    //   console.log('video finished');
    //   this.videoService.setVideoFinished(
    //     this.authService.dominioId,
    //     this.authService.userId,
    //     this.video.videoRef.ref.id
    //   );
    //   playerInstance.off('complete');
    // });

    return playerInstance;
  }
}
