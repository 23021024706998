import { Directive, EventEmitter, OnDestroy, OnInit } from '@angular/core';
import { AbstractControl, FormGroup } from '@angular/forms';
import { Router } from '@angular/router';
import { Subject } from 'rxjs';
import { takeUntil } from 'rxjs/operators';
import { formGroupIterator } from 'src/app/utils/form.helpers';

@Directive()
export abstract class SlideBaseComponent implements OnInit, OnDestroy {
  title: string;
  formEmitter = new EventEmitter<any>();
  nextSlideEmitter = new EventEmitter<number>();
  form: FormGroup;
  initialValue: any;
  extraInputs: any;
  previewMode: boolean;
  editMode: boolean;
  canEdit: boolean;

  protected onDestroy$ = new Subject<void>();

  constructor() {}

  ngOnInit() {
    this.initForm();
    if (this.form) {
      this.subscribeForm();
      if (this.initialValue) {
        this.form.patchValue(this.initialValue);
        // console.log('--initialValue', this.initialValue);
        formGroupIterator(this.form, (field: AbstractControl) => {
          if (field.value) {
            field.markAsTouched();
            field.markAsDirty();
          }
        });
        this.formEmitter.emit(this.form);
      } else {
        this.formEmitter.emit(this.form);
      }
    }
    this.onInit();
  }

  ngOnDestroy() {
    this.onDestroy$.next();
    this.onDestroy$.complete();
    this.onDestroy();
  }

  private subscribeForm() {
    this.form.statusChanges.pipe(takeUntil(this.onDestroy$)).subscribe(() => {
      this.formEmitter.emit(this.form);
    });
  }

  goToNextSlide() {
    this.nextSlideEmitter.emit();
  }

  protected abstract initForm(): void;
  protected abstract onInit(): void;
  protected abstract onDestroy(): void;
}
