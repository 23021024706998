<div class="slider">
  <div
    class="slider-container"
    [class.first]="currentIndex === 0"
    *ngIf="slides && slides.length > 0"
  >
    <!-- <div class="slider-header">
     <h1>{{ slides[currentIndex].title | translate | titlecase }}</h1>
    </div> -->

    <div class="slider-content">
      <!-- <div class="slide-form" *ngIf="canEdit">   -->

      <div class="visualize-area">
        <div class="slide-area">
          <div
            class="slide"
            *ngFor="let slide of slides; let i = index"
            [hidden]="i !== currentIndex"
          >
            <hr-slide
              [slide]="slide"
              (nextSlideEmitter)="onNextSlide($event)"
            ></hr-slide>

            <!-- <div *ngIf="!slides[currentIndex].canEdit" class="slide-title">
            {{ i + 1 | paddingNumber }}. {{ slide?.initialValue?.slideName }}
          </div> -->
          </div>
        </div>
      </div>
    </div>
    <div class="slider-footer" [hidden]="this.currentIndex === 0">
      <div class="footer-area">
        <div class="slider-footer-item btn">
          <button
            class="hr-button-primary-outline"
            [hidden]="this.currentIndex < 2 || currentIndex + 2 > slides.length"
            (click)="goBack(currentIndex)"
          >
            <span>Voltar</span>
          </button>
        </div>
        <div class="slider-footer-item menu">
          <div
            *ngFor="let slide of slidesButton"
            [hidden]="this.currentIndex === 0 || currentIndex + 2 > slides.length"
            class="slider-list-area"
          >
            <!-- *ngIf="noButtonSlides.indexOf(i) === -1" -->
            <div class="item-list-area">
              <div class="item-list" (click)="goTo(slide.index)">
                <div class="slider-footer-sub-item" *ngIf="getCurrentIconType(slide.initialValue?.type) as typeKey">
                  <svg-icon
                    class="hr-svg-icon"
                    src="/assets/icons/modules/carta-oferta/candidate-menu/{{ typeKey }}{{
                      this.currentIndex !== slide.index ? '-off' : ''
                    }}.svg"
                  >
                  </svg-icon>
                  <span
                    class="slider-footer-item-span"
                    [class.selected]="this.currentIndex === slide.index"
                    >{{
                      "offerLetter.candidateMenu.dynamic." +
                        typeKey | translate
                    }}</span
                  >
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="slider-footer-item btn">
          <button
            class="hr-button-primary"
            (click)="goForward(currentIndex)"
            [disabled]="!canFinish && this.currentIndex === slides.length - 1"
            [hidden]="this.currentIndex === 0 || currentIndex + 4 > slides.length"
          >
            <span *ngIf="!finishLocked">{{
              currentIndex + 1 === slides.length ? "Finalizar" : "Avançar"
            }}</span>
            <div *ngIf="finishLocked" class="loader">Loading...</div>
          </button>
        </div>
      </div>
    </div>
  </div>
</div>

<div class="slider-actions-mobile" [hidden]="this.currentIndex === 0">
  <button
    class="hr-button-primary-outline back"
    [hidden]="this.currentIndex < 2 || currentIndex + 2 > slides.length"
    (click)="goBack(currentIndex)"
  >
    <span>Voltar</span>
  </button>

  <button
    class="hr-button-primary forward"
    (click)="goForward(currentIndex)"
    [disabled]="!canFinish && this.currentIndex === slides.length - 1"
    [hidden]="this.currentIndex === 0 || currentIndex + 4 > slides.length"
  >
    <span *ngIf="!finishLocked">{{
      currentIndex + 1 === slides.length ? "Finalizar" : "Avançar"
    }}</span>
    <div *ngIf="finishLocked" class="loader">Loading...</div>
  </button>
</div>

<div
  class="slider-footer-mobile"
  [hidden]="this.currentIndex === 0 || currentIndex + 2 > slides.length"
>
  <div class="slider-footer-item">
    <hr-list-slider [noGradient]="true">
      <div
        *ngFor="let slide of slidesButton"
        [hidden]="this.currentIndex === 0"
      >
        <!-- *ngIf="noButtonSlides.indexOf(i) === -1" -->
        <div class="item-list-area" *ngIf="getCurrentIconType(slide.initialValue?.type) as typeKey">
          <div class="item-list" (click)="goTo(slide.index)">
            <div class="slider-footer-sub-item">
              <svg-icon
                class="hr-svg-icon"
                src="/assets/icons/modules/carta-oferta/candidate-menu/{{
                  typeKey
                }}{{ this.currentIndex !== slide.index ? '-off' : '' }}.svg"
              >
              </svg-icon>
              <span
                class="slider-footer-item-span"
                [class.selected]="this.currentIndex === slide.index"
                >{{
                  "offerLetter.candidateMenu.dynamic." +
                    typeKey | translate
                }}</span
              >
            </div>
          </div>
        </div>
      </div>
    </hr-list-slider>
  </div>
</div>
