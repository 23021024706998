<div
  (click)="openSelect()"
  class="hr-form-field-input"
  [ngClass]="{
    'hr-form-field-error': ngControl && ngControl.errors && (ngControl.touched || ngControl.dirty),
    'hr-form-field-readonly': readonly,
    'hr-form-field-disabled': control?.disabled
  }"
  #formSelect
>
  <label [for]="id" class="hr-text-selection-disabled hr-default-label" *ngIf="label">{{ label | translate }}</label>
  <div class="hr-default-field">
    <input
      class="hr-default-input"
      [id]="id"
      type="text"
      [(ngModel)]="itemSelected"
      (change)="onChange($event)"
      (blur)="blur.emit($event)"
      [placeholder]="placeholder"
      disabled
    />

    <mat-icon *ngIf="openSelectSearch === false">keyboard_arrow_down</mat-icon>
    <mat-icon *ngIf="openSelectSearch === true">keyboard_arrow_up</mat-icon>
  </div>
  <div class="hr-default-field-error">
    <svg-icon class="hr-svg-icon" src="../../../assets/icons/shared/info.svg"></svg-icon>
    <span>{{ ngControl && ngControl.errors ? getError() : '' }}</span>
  </div>
</div>

<div
  *ngIf="openSelectSearch === true"
  (hrClickOutside)="closeSelect()"
  [isOpen]="openSelectSearch"
  class="hr-form-field-checklist"
  [ngClass]="{
    'hr-form-field-readonly': readonly,
    'hr-form-field-disabled': control?.disabled
  }"
>
  <div class="hr-search-bar" *ngIf="openSelectSearchBar">
    <svg-icon class="hr-svg-icon" src="/assets/icons/components/search.svg"></svg-icon>
    <input type="text" [placeholder]="searchPlaceholder" [formControl]="searchFormControl" />
  </div>

  <div class="list">
    <div class="items hr-text-selection-disabled">
      <div style="word-break: break-all">
        <div class="item" *ngFor="let item of filteredItems$ | async">
          <div class="click-area" (click)="setValue(item)">
            <span class="name">{{ item.label | translate }}</span>
            <span class="codigo">{{ item.description | translate }}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="item" *ngIf="!(filteredItems$ | async)">
      <span class="hr-no-items" style="padding-left: 20px">{{ noItemsFoundLabel }}</span>
    </div>
  </div>
  <div class="hr-default-field-error">
    <svg-icon class="hr-svg-icon" src="../../../assets/icons/shared/info.svg"></svg-icon>
    <span>{{ ngControl && ngControl.errors && (ngControl.touched || ngControl.dirty) ? getError() : '' }}</span>
  </div>
</div>
