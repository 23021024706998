<div
  class="hr-form-field-textarea"
  [ngClass]="{
    'hr-form-field-error': ngControl && ngControl.errors && (ngControl.touched || ngControl.dirty),
    'hr-form-field-readonly': readonly,
    'hr-form-field-disabled': control?.disabled,
    'hr-form-field-focused': focused
  }"
>
  <label [for]="id" class="label" *ngIf="label">{{ label }}</label>
  <div class="field">
    <textarea
      [id]="id"
      hrResizeTextarea
      [placeholder]="placeholder"
      [formControl]="control"
      [attr.maxLength]="maxLength"
      [attr.minLength]="minLength"
      (focus)="focused = true"
      (focusout)="focused = false"
      [readonly]="readonly"
    ></textarea>
  </div>

  <div class="hr-default-field-error">
    <svg-icon class="hr-svg-icon" src="../../../assets/icons/shared/info.svg"></svg-icon>
    <span>{{ ngControl && ngControl.errors && (ngControl.touched || ngControl.dirty) ? getError() : '' }}</span>
  </div>
</div>
