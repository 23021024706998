<div class="hr-slide-area">
  <div class="ol-template-back-bar"></div>
  <div class="ol-template-slide-data">
    <div class="slide-data-area">
      <div class="ol-template-slide-info">
        <div
          class="ol-template-slide-title"
          [innerHTML]="initialValue.title"
        ></div>
        <div
          class="ol-template-slide-description"
          [innerHTML]="initialValue.description"
        ></div>
      </div>
      <div class="images-area">
        <img
          class="first-image"
          [src]="initialValue.firstImageUrl"
          alt="logo da empresa"
        />
        <img
          class="second-image"
          [src]="initialValue.secondImageUrl"
          alt="logo da empresa"
        />
      </div>
    </div>
  </div>
</div>
