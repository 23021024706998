import { AbstractControl, ValidationErrors } from '@angular/forms';
import { FormErrors } from './form.model';

class FormUtils {
  private defaultErrorList: FormErrors = {
    email: 'Email ínvalido',
    required: `campo obrigatório`,
    minlength: `o número mínimo de caracteres é #{requiredLength}.`,
    maxlength: `o número máximo de caracteres é #{requiredLength}.`,
    min: `não pode ser menor que #{min}.`,
    max: `não pode ser maior que #{max}.`,
    unique: 'este termo já existe.',
    forbiddenName: 'o termo #{value} não pode ser utilizado.',
    emailAlreadyInUse: 'Email já usado por outro candidato.',
  };

  public getError(
    control: AbstractControl,
    customErrorList?: FormErrors
  ): string {
    const controlErrors: ValidationErrors = control.errors;

    let errorLabel = `inválido`;

    let errorList: { [error: string]: string };
    if (customErrorList) {
      errorList = { ...this.defaultErrorList, ...customErrorList };
    } else {
      errorList = this.defaultErrorList;
    }

    const errorKey =
      Object.keys(controlErrors).length > 0
        ? Object.keys(controlErrors)[0]
        : null;
    if (errorKey) {
      errorLabel = errorList[errorKey];
      errorLabel = this.replaceVariables(errorLabel, controlErrors[errorKey]);
    }

    return errorLabel
      ? errorLabel.charAt(0).toUpperCase() + errorLabel.slice(1)
      : null;
  }

  private replaceVariables(error: string, errorData: FormErrors) {
    if (!errorData) {
      return error;
    }

    const regex = new RegExp(
      '#{(' + Object.keys(errorData).join('|') + ')}',
      'g'
    );

    if (error) {
      return error.replace(regex, (value) => {
        const label = errorData[value.slice(2, value.length - 1)];
        return label !== undefined || label !== null ? label : error;
      });
    }

    return '';
  }
}
export default new FormUtils();
