import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/compat/firestore';
import { map, Observable } from 'rxjs';
import { Md5 } from 'ts-md5/dist/md5';
import { IJWConstants, IVideoObject, IVideoView } from '../models/video.model';

const VIDEO_TIMEOUT = 216000;
@Injectable()
export class VideoService {
  constructor(private firestore: AngularFirestore) {
    console.log('constructing video');
  }

  getVideo(videoObject: IVideoObject): Observable<IVideoView> {
    return this.firestore.doc('/constants/videos').get().pipe(map(videoConsts => {
      if (!videoConsts.exists) {
        return undefined;
      }

      const keys = videoConsts.data() as IJWConstants;
      const secret = keys.jwSecret;
      const expDate = (new Date().getTime() / 1000 + VIDEO_TIMEOUT).toFixed();
      const md5 = new Md5();
      const manifestPath = `manifests/${videoObject.mediaKey}.m3u8:${expDate}:${secret}`;
      const sig = md5.appendStr(manifestPath).end();

      return {
        manifest: `manifests/${videoObject.mediaKey}.m3u8?sig=${sig}&exp=${expDate}`,
        preview: `https://cdn.jwplayer.com/thumbs/${videoObject.mediaKey}.jpg`,
      };
    }))
  }

  // setVideoStarted(dominioID: string, userId: string, videoId: string) {
  //   this.firestore
  //     .doc(`empresaMae/${dominioID}/ambientacoes/${userId}`)
  //     .get()
  //     .toPromise()
  //     .then((ambientacao) => {
  //       if (!ambientacao.exists) {
  //         console.log('Ambientação não encontrada');
  //         return;
  //       }

  //       let videosVistos: any[] = [];

  //       if (ambientacao.data().videosVistos) {
  //         videosVistos = ambientacao.data().videosVistos;
  //       }

  //       if (videosVistos.findIndex((v) => v.id === videoId) > -1) {
  //         return;
  //       }

  //       videosVistos.push({
  //         id: videoId,
  //         startedAt: new Date(),
  //       });

  //       ambientacao.ref.update({ videosVistos: videosVistos }).catch((err) => {
  //         console.log('Error on set video started', err);
  //         throw new Error('set-video-started');
  //       });
  //     });
  // }

  // setVideoFinished(dominioID: string, userId: string, videoId: string) {
  //   this.firestore
  //     .doc(`empresaMae/${dominioID}/ambientacoes/${userId}`)
  //     .get()
  //     .toPromise()
  //     .then((ambientacao) => {
  //       if (!ambientacao.exists) {
  //         console.log('Ambientação não encontrada');
  //         return;
  //       }

  //       let videosVistos: any[] = ambientacao.data().videosVistos;

  //       let index = videosVistos.findIndex((v) => v.id === videoId);
  //       if (index >= 0) {
  //         if (videosVistos[index].finishedAt) {
  //           return;
  //         }
  //         videosVistos[index].finishedAt = new Date();
  //       }

  //       ambientacao.ref.update({ videosVistos: videosVistos }).catch((err) => {
  //         console.log('Error on set video started', err);
  //         throw new Error('set-video-started');
  //       });
  //     });
  // }

  // setUserVisualization(data: Date, userId: string, videoRef: VideoView) {
  //   videoRef.ref.collection('/views').add({
  //     userId,
  //     dataVisualizacao: data,
  //   });
  // }
}
