import { Component, Input, OnInit } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import { take } from 'rxjs/operators';
import { ISlide } from 'src/app/shared/slider/slider.model';
import { ITemplate, SlideType } from 'src/app/models/templates.model';
import { DynamicAboutComponent } from './dynamic-about/dynamic-about.component';
import { DynamicCoverComponent } from './dynamic-cover/dynamic-cover.component';
import { DynamicGroupsComponent } from './dynamic-groups/dynamic-groups.component';
import { DynamicOfferDetailComponent } from './dynamic-offer-details/dynamic-offer-detail.component';
import { DynamicOfferComponent } from './dynamic-offer/dynamic-offer.component';
import { DynamicWelcomeComponent } from './dynamic-welcome/dynamic-welcome.component';
import { DynamicQuestionComponent } from './dynamic-question/dynamic-question.component';
import { DynamicBenefitsComponent } from './dynamic-benefits/dynamic-benefits.component';
import { DynamicAcceptedOfferComponent } from './dynamic-accepted-offer/dynamic-accepted-offer.component';
import { DynamicRejectedOfferComponent } from './dynamic-rejected-offer/dynamic-rejected-offer.component';
import { lastValueFrom } from 'rxjs';

@Component({
  selector: 'hr-dynamic',
  templateUrl: './dynamic.component.html',
  styleUrls: ['./dynamic.component.scss'],
})
export class DynamicComponent implements OnInit {
  @Input() template: ITemplate;
  @Input() slideIndex: string;
  @Input() canEdit: boolean;

  slides: ISlide[];

  constructor(private storage: AngularFireStorage) {}

  ngOnInit() {
    this.loadSlides();
  }

  private async loadSlides() {
    if (!this.slides) {
      this.slides = await Promise.all(this.initSlides());
    }
  }

  private initSlides() {
    // nomeAnjo: string, draft?: any[], isEdition = false
    // if (!draft) {
    //   draft = new Array(4).fill(null);
    // }
    return this.template.slides.map(async (slide) => {
      const proms = [];
      for (const key of Object.keys(slide)) {
        if (slide[key] instanceof Array) {
          for (const arrayItem of slide[key]) {
            for (const itemKey of Object.keys(arrayItem)) {
              if (itemKey.endsWith('Image')) {
                const imageRef = arrayItem[itemKey];
                if (imageRef) {
                  proms.push(
                    this.storage
                      .ref(imageRef)
                      .getDownloadURL()
                      .pipe(take(1))
                      .toPromise()
                      .then((url) => (arrayItem[`${itemKey}Url`] = url))
                  );
                }
              }
            }
          }
        } else if (key.endsWith('Image')) {
          const imageRef = slide[key];
          if (imageRef) {
            proms.push(
              this.storage
                .ref(imageRef)
                .getDownloadURL()
                .pipe(take(1))
                .toPromise()
                .then((url) => (slide[`${key}Url`] = url))
            );
          }
        } else if (key === 'videoPath' && slide[key]) {
          proms.push(
            lastValueFrom(
              this.storage.ref(slide[key]).getDownloadURL().pipe(take(1))
            ).then((url) => (slide['videoUrl'] = url))
          );
        }
      }

      await Promise.all(proms);
      return {
        title: this.template.title,
        initialValue: slide,
        canEdit: this.canEdit ? true : false,
        component: this.getSlideComponent(slide.type),
      };
    });
  }

  private getSlideComponent(slideType: SlideType) {
    switch (slideType) {
      case 'cover':
        return DynamicCoverComponent;
      case 'welcome':
        return DynamicWelcomeComponent;
      case 'about':
        return DynamicAboutComponent;
      case 'group':
        return DynamicGroupsComponent;
      case 'offer':
        return DynamicOfferComponent;
      case 'offer-detail':
        return DynamicOfferDetailComponent;
      case 'benefits':
        return DynamicBenefitsComponent;
      case 'question':
        return DynamicQuestionComponent;
      case 'accepted-offer':
        return DynamicAcceptedOfferComponent;
      case 'rejected-offer':
        return DynamicRejectedOfferComponent;
      default:
        return null;
    }
  }
}
