import { Injectable } from '@angular/core';
import { AngularFireStorage } from '@angular/fire/compat/storage';
import Compressor from 'compressorjs';
import { lastValueFrom } from 'rxjs';

@Injectable()
export class ImageService {
  metadata = {
    contentType: 'image/jpeg',
    cacheControl: 'public, max-age=31536000',
    type: 'image/jpeg',
  };

  constructor(private storage: AngularFireStorage) {}

  getFormatedPicture(file, _onFinish) {
    this.getOrientation(file, (orientation) => {
      this.getBase64(file, orientation, _onFinish);
    });
  }

  urltoFile(url: string, filename: string, mimeType = null) {
    mimeType = mimeType || (url.match(/^data:([^;]+);/) || '')[1];
    return fetch(url)
      .then(function (res) {
        return res.arrayBuffer();
      })
      .then(function (buf) {
        return new File([buf], filename, { type: mimeType });
      });
  }

  private getOrientation(file, callback) {
    let reader: any;
    reader = new FileReader();

    reader.onload = (event) => {
      const view = new DataView(event.target.result);

      if (view.getUint16(0, false) !== 0xffd8) {
        return callback(-2);
      }

      const length = view.byteLength;
      let offset = 2;

      while (offset < length) {
        const marker = view.getUint16(offset, false);
        offset += 2;

        if (marker === 0xffe1) {
          if (view.getUint32((offset += 2), false) !== 0x45786966) {
            return callback(-1);
          }
          const little = view.getUint16((offset += 6), false) === 0x4949;
          offset += view.getUint32(offset + 4, little);
          const tags = view.getUint16(offset, little);
          offset += 2;

          for (let i = 0; i < tags; i++) {
            if (view.getUint16(offset + i * 12, little) === 0x0112) {
              return callback(view.getUint16(offset + i * 12 + 8, little));
            }
          }
          // tslint:disable-next-line:no-bitwise
        } else if ((marker & 0xff00) !== 0xff00) {
          break;
        } else {
          offset += view.getUint16(offset, false);
        }
      }
      return callback(-1);
    };

    reader.readAsArrayBuffer(file.slice(0, 64 * 1024));
  }

  getBase64(file, orientation, _onFinish) {
    const reader = new FileReader();
    reader.readAsDataURL(file);

    reader.onload = () => {
      const base64 = reader.result;
      if (file.name && file.name.includes('.gif')) {
        _onFinish(base64);
        return;
      }
      this.resetOrientation(base64, orientation, (resetBase64Image) => {
        _onFinish(resetBase64Image);
      });
    };
    reader.onerror = (error) => {
      console.log('Error: ', error);
    };
  }

  getBase64Promise(file: File): Promise<string> {
    return new Promise((resolve, reject) => {
      const reader = new FileReader();
      reader.readAsDataURL(file);
      reader.onload = () => resolve(reader.result as string);
      reader.onerror = (error) => reject(error);
    });
  }

  private resetOrientation(srcBase64, srcOrientation, callback) {
    const img = new Image();

    img.onload = () => {
      const width = img.width,
        height = img.height,
        canvas = document.createElement('canvas'),
        ctx = canvas.getContext('2d');

      // set proper canvas dimensions before transform & export
      if (4 < srcOrientation && srcOrientation < 9) {
        canvas.width = height;
        canvas.height = width;
      } else {
        canvas.width = width;
        canvas.height = height;
      }

      // transform context before drawing image
      switch (srcOrientation) {
        case 2:
          ctx.transform(-1, 0, 0, 1, width, 0);
          break;
        case 3:
          ctx.transform(-1, 0, 0, -1, width, height);
          break;
        case 4:
          ctx.transform(1, 0, 0, -1, 0, height);
          break;
        case 5:
          ctx.transform(0, 1, 1, 0, 0, 0);
          break;
        case 6:
          ctx.transform(0, 1, -1, 0, height, 0);
          break;
        case 7:
          ctx.transform(0, -1, -1, 0, height, width);
          break;
        case 8:
          ctx.transform(0, -1, 1, 0, 0, width);
          break;
        default:
          break;
      }

      // draw image
      ctx.drawImage(img, 0, 0);

      // export base64
      callback(canvas.toDataURL());
    };

    img.src = srcBase64;
  }

  /**
   *
   * @param imgObj
   * objeto contendo o file da imagem e o id gerado
   * @param path
   * string com o caminho dos diretórios em que a imagem vai ser salva,
   * sobrescrevendo o id de imgObj
   */
  // async uploadImage({ id, file }: { id: string; file: string }, path?: string) {
  //   const ref = this.storage.ref(`${path ? path : 'assets/logos/'}${id}`);
  //   const task = ref.putString(file, 'data_url', this.metadata);
  //   try {
  //     const res = await task;
  //     if (res.state === 'success') {
  //       return res.ref.getDownloadURL() as Promise<string>;
  //     }
  //   } catch (err) {
  //     console.log(err);
  //     return null;
  //   }
  // }

  deleteImage(id: string, path: string) {
    this.storage.ref(`${path}${id}`).delete();
  }

  getFile(ref: string) {
    return lastValueFrom(this.storage.ref(ref).getDownloadURL()).then(
      (url:string) => url
    );
  }

  compressFile(file: Blob, _onFinish: Function) {
    const onSuccess = (compressedImg: Blob) => {
      _onFinish(compressedImg);
    };

    const onError = (err) => {
      console.log('opppssss ----> ', err);
    };

    const compressed = new Compressor(file, {
      quality: 0.6,
      maxHeight: 2048,
      maxWidth: 2048,
      success: onSuccess.bind(this),
      error: onError.bind(this),
    });
  }
}
