import { Component } from '@angular/core';
import { FormBuilder, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { RejectedAlertComponent } from 'src/app/components/rejected-alert/rejected-alert.component';
import { RejectedReason } from 'src/app/models/rejected-reason-alert.models';
import { ITemplateQuestion } from 'src/app/models/templates.model';
import { AuthService } from 'src/app/services/auth.service';
import { PropostaSalarialService } from 'src/app/services/proposta-salarial.service';
import { SlideBaseComponent } from 'src/app/shared/slider/slide/slide-base.component';
import { CO_STATUS_CONST } from 'src/app/utils/utils';

@Component({
  selector: 'hr-dynamic-question',
  templateUrl: './dynamic-question.component.html',
  styleUrls: ['./dynamic-question.component.scss'],
  providers: [FormBuilder],
})
export class DynamicQuestionComponent extends SlideBaseComponent {
  override initialValue: ITemplateQuestion;
  cargo: string;
  salario: number;
  dataLimite: string;
  aceito = CO_STATUS_CONST.ACEITO;
  disabledBttns: boolean;
  constructor(
    private formBuilder: FormBuilder,
    public propostaService: PropostaSalarialService,
    private dialog: MatDialog,
    private authService: AuthService
  ) {
    super();
    this.cargo = this.propostaService.getView().cargo;
    this.salario = this.propostaService.getView().salario;
    const dataLimiteTimestamp = this.propostaService.getView().dataLimite;
    this.dataLimite = dataLimiteTimestamp
      ? dataLimiteTimestamp.toDate().toLocaleDateString()
      : null;
  }

  protected onInit(): void {
    this.disableBttns();
  }
  protected onDestroy(): void {}

  protected initForm(): void {
    this.form = this.formBuilder.group({
      slideName: [null, [Validators.required, Validators.maxLength(20)]],
      title: [null, [Validators.required, Validators.maxLength(80)]],
      description: [null, [Validators.required, Validators.maxLength(1500)]],
      backgroundImage: [null, [Validators.required]],
      backgroundImageUrl: [null, [Validators.required]],
      logoImage: [null, [Validators.required]],
      logoImageUrl: [null, [Validators.required]],
    });
  }

  disableBttns() {
    this.disabledBttns = this.authService.isPreview ? true : false;
  }

  async openModalRejectOffer() {
    this.dialog
      .open(RejectedAlertComponent, {
        panelClass: ['hr-modal', 'hr-thin-modal', 'hr-modal-reject'],
        backdropClass: 'hr-modal-backdrop-close',
      })
      .afterClosed()
      .subscribe(async (reason?: RejectedReason) => {
        if (reason) {
          this.onRejectOffer(reason);
        }
      });
  }

  async onAcceptOffer() {
    await this.propostaService.aceitarOferta();
    this.nextSlideEmitter.emit();
  }

  async onRejectOffer(reason: RejectedReason) {
    await this.propostaService.recusarOferta(reason);
    this.nextSlideEmitter.emit(2);
  }

  getCurrency(value: string | number) {
    let valueSplit = value.toString().split(/[,.]+/);
    let cents = valueSplit[valueSplit.length - 1];
    if (valueSplit.length === 1 || cents.length > 2) {
      cents = '00';
      return this.setMilhar(valueSplit.join(), cents);
    } else {
      cents = cents.length === 2 ? cents : `${cents}0`;
      valueSplit.pop();
      return this.setMilhar(valueSplit.join(''), cents);
    }
  }

  setMilhar(int: string, cents: string) {
    if (int.length > 3) {
      let newValue = int.slice(0, -3) + '.' + int.slice(-3);
      return newValue + ',' + cents;
    }
    return int + ',' + cents;
  }
}
