<div
  class="hr-form-field-input"
  [ngClass]="{
    'hr-form-field-error': ngControl && ngControl.errors && (ngControl.touched || ngControl.dirty),
    'hr-form-field-readonly': readonly,
    'hr-form-field-disabled': control?.disabled,
    'hr-form-field-focused': focused
  }"
>
  <label [for]="id" class="hr-text-selection-disabled hr-default-label" *ngIf="label">{{ label }}</label>

  <div class="hr-default-field">
    <input
      class="hr-default-input"
      type="text"
      [id]="id"
      [placeholder]="placeholder"
      [formControl]="control"
      [attr.maxLength]="maxLength"
      [attr.minLength]="minLength"
      [readonly]="readonly"
      (blur)="blur.emit($event)"
      (focusout)="focused = false"
      (focus)="focus.emit($event); focused = true"
      (keyup)="keyup.emit($event)"
    />
  </div>

  <div class="hr-default-field-error">
    <svg-icon class="hr-svg-icon" src="../../../assets/icons/shared/info.svg"></svg-icon>
    <span>{{ ngControl && ngControl.errors && (ngControl.touched || ngControl.dirty) ? getError() : '' }}</span>
  </div>
</div>
