<div class="hr-modal-close-button hr-tooltip-trigger" (click)="closeModal()">
  <svg-icon
    class="hr-svg-icon"
    src="../../../assets/icons/components/close-x.svg"
  ></svg-icon>

  <div class="hr-tooltip-content hr-mini-tooltip">
    <span>Fechar</span>
  </div>
</div>

<div class="modal-header">
  <h1>{{ data.name }}</h1>
  <div class="header-mobile" (click)="closeModal()">
    <div class="icon-area">
      <svg-icon
        class="hr-svg-icon"
        src="/assets/icons/shared/arrow-full.svg"
      ></svg-icon>
    </div>
    <span>{{ data.name }}</span>
  </div>
</div>

<div class="modal-content">
  <div class="data-image" *ngIf="data.image">
    <img [src]="data.image" alt="icone de {{ data.name }}" />
  </div>

  <div class="explanation">
    <p [innerHTML]="data.description"></p>
  </div>

  <div class="data-video" *ngIf="data.video || data.videoPath">
    <hr-video-player [videoObject]="data.video" [videoUrl]="videoUrl"></hr-video-player>
  </div>
</div>

<div class="modal-actions">
  <button class="hr-button-primary" (click)="closeModal()">
    <span>Ok, entendi</span>
  </button>
</div>
